import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import {
  CodeEditorComponentModule,
  DateValueFieldComponentModule,
  FormFieldComponentModule,
} from '@selfai-platform/pipeline-shared';
import { CardModule } from 'primeng/card';
import { CheckboxModule } from 'primeng/checkbox';
import { DropdownModule } from 'primeng/dropdown';
import { InputNumberModule } from 'primeng/inputnumber';
import { InputTextModule } from 'primeng/inputtext';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { EditSelectionFieldComponentModule } from '../edit-selection-field';
import { ConditionEditComponent } from './components';

@NgModule({
  imports: [
    CommonModule,
    InputTextModule,
    InputNumberModule,
    CheckboxModule,
    ReactiveFormsModule,
    DropdownModule,
    DateValueFieldComponentModule,
    FormFieldComponentModule,
    CardModule,
    EditSelectionFieldComponentModule,
    InputTextareaModule,
    CodeEditorComponentModule,
  ],
  declarations: [ConditionEditComponent],
  exports: [ConditionEditComponent],
})
export class ConditionEditComponentModule {}
