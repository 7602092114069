<div class="toolbar py-0 pl-0">
  <div
    class="flex align-items-center gap-2"
    [style.max-width]="'calc(100% - ' + buttonContainer.clientWidth + 'px - 2rem)'"
  >
    <selfai-platform-scrollable-x-view
      [style.max-width]="'calc(100% - ' + addButtonContainer.clientWidth + 'px - 0.5rem)'"
    >
      <selfai-platform-workbook-pins
        class="flex align-items-center"
        [workspace]="workspace"
        [workbookId]="workbook.id"
      ></selfai-platform-workbook-pins>
    </selfai-platform-scrollable-x-view>
    <div #addButtonContainer class="border-left-2 surface-border">
      <button
        *ngIf="hasPermission(biEntityPermission.DASHBOARD_CREATE)"
        type="button"
        pButton
        size="large"
        appearance="icon"
        class="p-button-success p-button-icon-only p-button-link"
        icon="pi pi-plus-circle"
        tooltipPosition="top"
        [pTooltip]="'msg.comm.ui.create.board' | translate"
        (click)="creatingDashboardShow.emit(true)"
      ></button>
    </div>
  </div>
  <div #buttonContainer class="flex gap-2">
    <button
      *ngIf="hasPermission(biEntityPermission.DASHBOARD_EDIT)"
      type="button"
      pButton
      size="large"
      appearance="icon"
      class="p-button p-button-primary"
      icon="pi pi-pencil"
      [pTooltip]="'msg.navbar.edit' | translate"
      tooltipPosition="top"
      (click)="workbookActionsService.setUpdateDashboardConfig({ view: true })"
    ></button>

    <button
      type="button"
      pButton
      size="large"
      appearance="icon"
      class="p-button p-button-primary"
      *ngIf="{ activeMode: workbookActionsService.isActiveMode$ | async } as obs"
      [icon]="obs.activeMode ? 'pi pi-lock-open' : 'pi pi-lock'"
      [pTooltip]="
        obs.activeMode ? ('msg.page.prediction.analysis' | translate) : ('msg.workspacesEdit.view' | translate)
      "
      tooltipPosition="top"
      (click)="workbookActionsService.setActiveMode(!obs.activeMode)"
    ></button>

    <ng-container *ngIf="menuMoreItems$ | async as menuMoreItems">
      <ng-container *ngIf="menuMoreItems.length">
        <button
          type="button"
          pButton
          size="large"
          class="p-button-primary"
          appearance="icon"
          (click)="menu.toggle($event)"
          icon="pi pi-ellipsis-v"
          tooltipPosition="top"
          pTooltip="{{ 'msg.menu-actions-more.button-tooltip' | translate }}"
        ></button>
        <p-menu #menu [model]="menuMoreItems" [popup]="true"></p-menu>
      </ng-container>
    </ng-container>
  </div>
</div>

<p-confirmDialog></p-confirmDialog>
