import { DataListViewItem } from '@selfai-platform/shell';

export interface IModel {
  id: string;
  name: string;
  description: string;
  last_updated_timestamp: number;
  creation_timestamp: number;
  latest_versions?: IModelsRegistryModelVersion[];
  tags?: IModelsRegistryModelTag[];
}

export interface ModelsRegistryListItem extends DataListViewItem, IModel {
  stagingVersion?: string;
  productionVersion?: string;
  latestVersion?: string;
}

export interface ModelsRegistryVersionsListItem extends DataListViewItem, IModelsRegistryModelVersion {}

export interface ModelsRegistryList {
  models: ModelsRegistryListItem[];
  page?: {
    number: number;
    size: number;
    totalElements: number;
    totalPages: number;
  };
}

export interface ModelsRegistryVersionsList {
  models: ModelsRegistryVersionsListItem[];
  page?: {
    number: number;
    size: number;
    totalElements: number;
    totalPages: number;
  };
}

export enum EModelsRegistryVersionCurrentStage {
  None = 'None',
  Production = 'Production',
  Staging = 'Staging',
}

export interface IModelsRegistryModelTag {
  key: string;
  value: string;
}

export enum EModelsRegistryVersionStatus {
  Ready = 'READY',
  Finished = 'FINISHED',
}

export interface IModelsRegistryModelVersion {
  id: string;
  creation_timestamp: number;
  current_stage: EModelsRegistryVersionCurrentStage;
  description: string;
  last_updated_timestamp: number;
  name: string;
  run_id: string;
  run_link: string;
  source: string;
  status: EModelsRegistryVersionStatus;
  version: string;
  tags?: IModelsRegistryModelTag[];
}

export interface IModelsRegistryRun {
  run: {
    data: {
      metrics?: { key: string; step: number; timestamp: number; value: number }[];
      params?: { key: string; value: string }[];
      tags: { key: string; value: string }[];
    };
    info?: {
      artifact_uri: string;
      end_time: number;
      experiment_id: string;
      lifecycle_stage: EModelsRegistryVersionCurrentStage;
      run_id: string;
      run_name: string;
      run_uuid: string;
      start_time: number;
      status: EModelsRegistryVersionStatus;
      user_id: string;
    };
  };
}

type DataType = 'binary' | 'datetime' | 'boolean' | 'double' | 'float' | 'integer' | 'long' | 'string';

export type ColumnType = ScalarType | ArrayType | ObjectType;
export type ScalarType = {
  type: DataType;
};
export type ArrayType = {
  type: 'array';
  items: ColumnType;
};
export type ObjectType = {
  type: 'object';
  properties: { [name: string]: ColumnType & { required?: boolean } };
};
export type ColumnSpec = ColumnType & {
  name: string;
  required?: boolean;
  optional?: boolean;
};

export type TensorSpec = {
  type: 'tensor';
  'tensor-spec': {
    dtype: string;
    shape: Array<number>;
  };
  required?: boolean;
  optional?: boolean;
};
