import { Injectable } from '@angular/core';
import {
  ModelsRegistryPermissionAction,
  PermissionService,
  PipelinePermissionEntity,
} from '@selfai-platform/shared';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ModelsRegistryPermissionService {
  constructor(protected readonly permissionService: PermissionService) {}

  public checkPermission(
    permissionAction: ModelsRegistryPermissionAction,
    isOwner?: boolean
  ): Observable<boolean> {
    return this.permissionService.checkPermission({
      entity: PipelinePermissionEntity.ModelsRegistry,
      isOwner,
      action: permissionAction,
    });
  }
}
