import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { PipelineConfig, PipelineConfigService } from '@selfai-platform/shared';
import { Observable } from 'rxjs';
import { v4 as uuidv4 } from 'uuid';
import { DataSourceApi, DataSourceParamsType } from '../models';

@Injectable({
  providedIn: 'root',
})
export class DataSourcesApiService {
  private readonly config: PipelineConfig;
  private readonly apiUrl: string;
  private readonly datasourcesPath = 'datasources';

  constructor(
    private http: HttpClient,
    private readonly pipelineConfigService: PipelineConfigService,
  ) {
    this.config = this.pipelineConfigService.getConfig();
    this.apiUrl = `${this.config.hosts.api}/datasourcemanager/${this.config.versions.url}`;
  }

  loadList(): Observable<DataSourceApi[]> {
    return this.http.get<DataSourceApi[]>(`${this.apiUrl}/${this.datasourcesPath}`);
  }

  loadDatasource(id: string): Observable<DataSourceApi> {
    return this.http.get<DataSourceApi>(`${this.apiUrl}/${this.datasourcesPath}/${id}`);
  }

  addDatasource(datasourceParams: DataSourceApi): Observable<DataSourceApi> {
    return this.http.post<DataSourceApi>(`${this.apiUrl}/${this.datasourcesPath}/${uuidv4()}`, datasourceParams.params);
  }

  updateDatasource(dataId: string | number, params: DataSourceParamsType): Observable<DataSourceApi> {
    return this.http.put<DataSourceApi>(`${this.apiUrl}/${this.datasourcesPath}/${dataId}`, params);
  }

  deleteDatasource(id: string): Observable<string> {
    return this.http.delete<string>(`${this.apiUrl}/${this.datasourcesPath}/${id}`);
  }
}
