<selfai-platform-data-list-view
  [layoutTypes]="['table']"
  [columns]="columns"
  [showEntityIcon]="true"
  [actions]="actions$ | async"
  [groupActions]="groupActions$ | async"
  [emptyMessage]="'shell.board.filter.ui.search-all.nodata' | translate"
  (renameItem)="saveName($event)"
>
  <ng-template selfaiPlatformDataListViewTemplateName="fieldValue.running" let-item>
    <ng-container *ngIf="item.running && ((canExecute(item) | async) || (canRun(item) | async))">
      <button
        *ngIf="!item.stopInProgress"
        type="button"
        pButton
        class="p-button-text p-button-link p-0"
        appearance="icon"
        (click)="stopSessionWorkflow(item.id)"
        icon="pi pi-power-off"
        tooltipPosition="left"
        pTooltip="{{ 'workflow-list.table.action.item.stop-session' | translate }}"
      ></button>
      <button
        *ngIf="item.stopInProgress"
        type="button"
        pButton
        class="p-button-text p-button-link p-0"
        appearance="icon"
        icon="pi pi-spin pi-spinner"
        tooltipPosition="left"
        pTooltip="{{ 'workflow-list.table.action.item.stop-in-progress' | translate }}"
      ></button>
    </ng-container>
  </ng-template>
</selfai-platform-data-list-view>
