import { Injectable } from '@angular/core';
import { DefaultDataService, HttpUrlGenerator } from '@ngrx/data';
import { IModel, IModelsRegistryModelVersion, ModelsRegistryVersionsListItem } from '@selfai-platform/pipeline-api';
import { HttpClient } from '@angular/common/http';
import { KE_MODELS_REGISTRY_VERSIONS_ENTITY_NAME } from '@selfai-platform/store';
import { map, Observable, ReplaySubject, switchMap, take } from 'rxjs';
import { Update } from '@ngrx/entity/src/models';
import { ModelsRegistryApiService } from '@selfai-platform/pipeline-api';

@Injectable({
  providedIn: 'root',
})
export class ModelsRegistryVersionsListDataService extends DefaultDataService<IModelsRegistryModelVersion> {
  public currentModel: ReplaySubject<IModel> = new ReplaySubject<IModel>(1);
  constructor(
    public override http: HttpClient,
    public override httpUrlGenerator: HttpUrlGenerator,
    private readonly modelsRegistryApiService: ModelsRegistryApiService,
  ) {
    super(KE_MODELS_REGISTRY_VERSIONS_ENTITY_NAME, http, httpUrlGenerator);
  }

  override getAll(): Observable<ModelsRegistryVersionsListItem[]> {
    return this.currentModel.pipe(
      take(1),
      switchMap((currentModel) => this.modelsRegistryApiService.getModelVersions(currentModel.name)),
      map((items) => items && items?.map((item) => ({ ...item, id: item.version }))),
    );
  }

  override getById(version: string): Observable<ModelsRegistryVersionsListItem> {
    return this.currentModel.pipe(
      take(1),
      switchMap((model) =>
        this.modelsRegistryApiService.getModelVersion(model.name, version).pipe(
          take(1),
          map((item) => ({ ...item, id: item.version })),
        ),
      ),
    );
  }

  override add(data: ModelsRegistryVersionsListItem): Observable<ModelsRegistryVersionsListItem> {
    return this.modelsRegistryApiService.addModelVersion(data).pipe(
      take(1),
      map((item) => ({ ...item })),
    );
  }

  override update(data: Update<ModelsRegistryVersionsListItem>): Observable<ModelsRegistryVersionsListItem> {
    return this.currentModel.pipe(
      take(1),
      switchMap(() =>
        this.modelsRegistryApiService.updateModelVersion(data).pipe(
          take(1),
          map((item) => ({ ...item })),
        ),
      ),
    );
  }

  override delete(id: string): Observable<string> {
    return this.modelsRegistryApiService.deleteModelVersion(id);
  }
}
