import { DestroyRef, Injectable } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { FormControl, FormControlState, FormGroup } from '@angular/forms';
import { WorkflowApiService } from '@selfai-platform/pipeline-api';
import {
  AdditionalParamFormGroup,
  CubeEditResult,
  CubeWorkflowData,
  ESparkClusterConfig,
  EWorkflowRunnerParamToRun,
  EWorkflowSelectionMode,
  GraphNode,
  WorkflowRunnerAdditionalParam,
  WorkflowRunnerData,
  WorkflowRunnerFormGroup,
  WorkflowRunnerLegacyData,
} from '@selfai-platform/pipeline-common';
import { DialogService } from '@selfai-platform/shell';
import { isEmpty } from 'lodash';
import { BehaviorSubject, distinctUntilChanged, filter, map, Observable } from 'rxjs';
import { normalizeDataWorkflowRunner } from '../../../converters/workflow-runner.normalizer';
import { SelectionStoreService } from '../../../services';

interface StoreData {
  nodeId: string;
  data: WorkflowRunnerData;
}

@Injectable()
export class WorkflowRunnerComponentService extends BehaviorSubject<StoreData> {
  public workflowList$ = this.workflowApiService.getAllWorkflows();

  constructor(
    dialogService: DialogService<CubeEditResult, CubeWorkflowData<WorkflowRunnerLegacyData>>,
    selectionStoreService: SelectionStoreService,
    private readonly workflowApiService: WorkflowApiService,
    private destroyRef: DestroyRef,
  ) {
    let data: StoreData = { data: {} } as StoreData;
    if (dialogService.data) {
      const {
        selectedNode: { id: nodeId, parameters },
      } = dialogService.data;
      if (parameters) {
        data = { nodeId, data: normalizeDataWorkflowRunner(parameters.serialize()) };
      }
    }
    super(data);
  }

  public getFormGroup(): FormGroup<WorkflowRunnerFormGroup> {
    const { data } = this.value;

    return new FormGroup<WorkflowRunnerFormGroup>({
      workflowSelectionMode: new FormControl(EWorkflowSelectionMode.WORKFLOW_LIST),
      workflow: new FormControl(null as FormControlState<null>),
      workflowExpression: new FormControl(null as FormControlState<null>),
      workflowParamToRun: new FormControl(EWorkflowRunnerParamToRun.UUID),
      workflowId: new FormControl({ value: data.workflowId, disabled: true }),
      workflowName: new FormControl({ value: data.workflowName, disabled: true }),
      stopFlowBeforeRun: new FormControl(data.stopFlowBeforeRun),
      clusterConfig: new FormControl<ESparkClusterConfig>(
        !isEmpty(data.sparkCluster) ? ESparkClusterConfig.Override : ESparkClusterConfig.Default,
      ),
    });
  }

  public getParametersFormGroups(): Observable<FormGroup<AdditionalParamFormGroup>[]> {
    return this.asObservable().pipe(
      map(({ data: { parameters } }) => parameters),
      filter(Boolean),
      map((parameters) => parameters.map(this.mapItemToFormGroup.bind(this))),
    );
  }

  public mapItemToFormGroup(item: WorkflowRunnerAdditionalParam): FormGroup<AdditionalParamFormGroup> {
    const notEmptyFormControl = new FormControl(item.notEmpty as boolean);
    const notEmptyCloneFormControl = this.cloneSynchronizedControl(notEmptyFormControl);
    const isArrayFormControl = new FormControl(item.isArray as boolean);
    const isArrayCloneFormControl = this.cloneSynchronizedControl(isArrayFormControl);

    return new FormGroup<AdditionalParamFormGroup>({
      id: new FormControl(item.id as string),
      name: new FormControl(item.name as string),
      query: new FormControl(item.query as string),
      notEmpty: notEmptyFormControl,
      notEmptyClone: notEmptyCloneFormControl,
      isArray: isArrayFormControl,
      isArrayClone: isArrayCloneFormControl,
    });
  }

  private cloneSynchronizedControl<T>(formControl: FormControl<T>): FormControl<T> {
    const cloneFormControl = new FormControl<T>(formControl.value);

    formControl.valueChanges
      .pipe(takeUntilDestroyed(this.destroyRef), distinctUntilChanged())
      .subscribe((value) => cloneFormControl.setValue(value));

    cloneFormControl.valueChanges
      .pipe(takeUntilDestroyed(this.destroyRef), distinctUntilChanged())
      .subscribe((value) => formControl.setValue(value));

    return cloneFormControl;
  }

  public setData(node: GraphNode<any>): void {
    const { id, parameters } = node;
    if (parameters) {
      this.next({ nodeId: id, data: normalizeDataWorkflowRunner(parameters.serialize() as any) });
    }
  }
}
