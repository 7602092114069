export const MODELS_REGISTRY_VERSIONS_TABLE_COLUMNS_CONFIG = [
  {
    labelTranslate: 'models-registry-list.table.columns.version',
    fieldCode: 'name',
    fieldType: 'text',
    classStyle: 'no-breaks short-text',
    width: '11rem',
    resizable: true,
    sortable: true,
  },
  {
    labelTranslate: 'models-registry-list.table.columns.created',
    fieldCode: 'creation_timestamp',
    fieldType: 'date',
    classStyle: 'no-breaks short-text',
    width: '12rem',
    resizable: true,
    sortable: true,
  },
  {
    labelTranslate: 'models-registry-list.table.columns.last-modified',
    fieldCode: 'last_updated_timestamp',
    fieldType: 'date',
    classStyle: 'no-breaks short-text',
    width: '12rem',
    resizable: true,
    sortable: true,
  },
  {
    labelTranslate: 'models-registry-list.table.columns.stage',
    fieldCode: 'current_stage',
    fieldType: 'text',
    classStyle: 'no-breaks short-text',
    sortable: true,
    resizable: true,
    width: '12rem',
  },
  {
    labelTranslate: 'models-registry-list.table.columns.description',
    fieldCode: 'description',
    fieldType: 'text',
    classStyle: 'no-breaks short-text',
    sortable: true,
    resizable: true,
    width: '12rem',
  },
];
