import { HttpErrorResponse } from '@angular/common/http';
import { LoadingError } from './loading-error';

export function handleHttpErrorResponse(response: HttpErrorResponse | unknown): LoadingError {
  if (response instanceof HttpErrorResponse && Object.prototype.hasOwnProperty.call(response.error, 'text')) {
    return response.error;
  }

  if (response instanceof Error) {
    return { text: response.message };
  }

  if (typeof response === 'string') {
    return { text: response };
  }

  return { text: 'Error' };
}
