import { ComponentRef, Injectable, OnDestroy, Renderer2, ViewContainerRef } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { DestroyService } from '@selfai-platform/shared';
import { takeUntil } from 'rxjs';
import { CubeDialogManagementService } from '../../services/cube-dialog-management.service';
import { DialogHeaderComponent } from './dialog-header.component';

// The service must be provided in the component that will init it. We need Renderer2 and ViewContainerRef from component
@Injectable()
export class DialogHeaderService implements OnDestroy {
  private componentRef: ComponentRef<DialogHeaderComponent> | null = null;

  constructor(
    private readonly translateService: TranslateService,
    private readonly destroy$: DestroyService,
    private readonly cubeDialogManagementService: CubeDialogManagementService,
    private readonly renderer: Renderer2,
    private readonly containerRef: ViewContainerRef,
  ) {}

  ngOnDestroy(): void {
    this.componentRef.destroy();
    this.componentRef = null;
  }

  public initCustomHeaderComponent(
    nodeId: string | null,
    nodeHeader: string,

    onCloseCb?: () => void,
    onSubmitCb?: () => void,
  ): void {
    // Init instance of DialogHeaderComponent
    this.componentRef = this.containerRef.createComponent(DialogHeaderComponent);
    this.componentRef.instance.header = nodeHeader;
    this.componentRef.instance.closeDialog.pipe(takeUntil(this.destroy$)).subscribe(() => {
      if (onCloseCb) {
        onCloseCb();
      } else {
        if (nodeId) {
          this.cubeDialogManagementService.closeCollapsed(nodeId);
        }
      }
    });
    if (nodeId) {
      this.componentRef.instance.nodeId = nodeId;

      this.componentRef.instance.clickSave.pipe(takeUntil(this.destroy$)).subscribe(() => {
        if (onSubmitCb) {
          onSubmitCb();
        }
      });
      this.componentRef.instance.setFocus.pipe(takeUntil(this.destroy$)).subscribe((nodeId) => {
        this.cubeDialogManagementService.setFocus(nodeId);
      });
      this.componentRef.instance.minimize.pipe(takeUntil(this.destroy$)).subscribe((nodeId) => {
        this.cubeDialogManagementService.collapse(nodeId);
      });
    }
    // Search rendered PrimeDialog header element
    const primeDialogHeader = document.getElementsByClassName('p-dialog-header')[0];
    // Clear all child elements of PrimeDialog
    while (primeDialogHeader.firstChild) {
      primeDialogHeader.removeChild(primeDialogHeader.lastChild);
    }
    // Connect DialogHeaderComponent to PrimeDialog
    this.renderer.appendChild(primeDialogHeader, this.componentRef.location.nativeElement);
    // Drop class for ability to find and append new rendered PrimeDialogs
    primeDialogHeader.classList.remove('p-dialog-header');
    if (!nodeId) {
      this.componentRef.instance.isEmpty = true;
    }
  }
}
