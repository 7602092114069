<p-confirmPopup></p-confirmPopup>

<ng-container
  *ngIf="{
    data: data$ | async,
    loading: loading$ | async,
    totalRecords: totalRecords$ | async,
    selectedItems: selectedItems$ | async,
  } as obs"
>
  <ng-container *ngIf="pageParams$ | async as pageParams">
    <p-table
      #table
      [value]="obs.data"
      [paginator]="!dataIsLazy"
      [rows]="pageParams.pageSize"
      [first]="pageParams.pageSize * (pageParams.pageNumber - 1)"
      [totalRecords]="obs.totalRecords"
      [sortField]="pageParams.sortField"
      [sortOrder]="pageParams.sortOrder === 'asc' ? 1 : -1"
      [lazy]="dataIsLazy"
      [loading]="obs.loading"
      [lazyLoadOnInit]="false"
      (onLazyLoad)="onLazyLoad($event)"
      dataKey="id"
      [selection]="obs.selectedItems"
      (selectionChange)="selectedItemsChange($event)"
      [showLoader]="false"
      [globalFilterFields]="globalFilterFields"
      [resizableColumns]="true"
      [stateKey]="tableName"
      stateStorage="local"
      styleClass="p-datatable-sm"
      [scrollable]="true"
      scrollHeight="flex"
      (onColResize)="onColResize($event)"
    >
      <ng-template pTemplate="loadingbody">
        <tr *ngFor="let item of [].constructor(pageParams.pageSize)" style="max-height: 35px" tabindex="-1">
          <td *ngIf="isShowedCheckbox">
            <p-tableHeaderCheckbox></p-tableHeaderCheckbox>
          </td>
          <td *ngIf="isFavoriteSupported" class="py-0">
            <button
              pButton
              type="button"
              class="p-button-text p-button-link p-button-lg p-0"
              appearance="icon"
              [icon]="'pi pi-star text-gray-800'"
            ></button>
          </td>
          <td *ngIf="showEntityIcon">
            <p-skeleton class="w-full"></p-skeleton>
          </td>
          <td *ngFor="let _ of [].constructor(columns.length)">
            <p-skeleton class="w-full"></p-skeleton>
          </td>
          <td></td>
        </tr>
      </ng-template>
      <ng-template pTemplate="header">
        <tr>
          <th *ngIf="isShowedCheckbox" style="width: 3rem">
            <p-tableHeaderCheckbox></p-tableHeaderCheckbox>
          </th>
          <th *ngIf="isFavoriteSupported" style="width: 4rem">
            <button
              pButton
              type="button"
              class="p-button-text p-button-link p-button-lg p-0"
              appearance="icon"
              [icon]="onlyFavorite ? 'pi pi-star-fill text-yellow-500' : 'pi pi-star text-gray-800'"
              (click)="filterByFavorite()"
            ></button>
          </th>
          <th *ngIf="showEntityIcon" style="width: 2rem"></th>
          <th
            *ngFor="let column of columns"
            selfaiPlatformResizableColumn
            id="{{ column.fieldCode }}"
            [ngStyle]="{
              width: column.width,
            }"
            [class]="column.classStyle"
            [pSortableColumn]="column.fieldCode.toString()"
            [pSortableColumnDisabled]="column.sortable !== true"
            pResizableColumn
            [pResizableColumnDisabled]="column.resizable !== true"
          >
            {{ column.labelTranslate | translate }}
            <p-sortIcon *ngIf="column.sortable" [field]="column.fieldCode.toString()"></p-sortIcon>
          </th>
          <th style="width: 14rem"></th>
        </tr>
      </ng-template>

      <ng-template pTemplate="body" let-item>
        <tr *ngIf="!obs.loading">
          <td *ngIf="isShowedCheckbox">
            <p-tableCheckbox [value]="item"></p-tableCheckbox>
          </td>
          <td *ngIf="isFavoriteSupported" class="py-0">
            <button
              pButton
              type="button"
              class="p-button-text p-button-link p-button-lg p-0"
              appearance="icon"
              pTooltip="{{
                (item.favorite ? 'data-list-view.remove-favorite' : 'data-list-view.add-favorite') | translate
              }}"
              [icon]="item.favorite ? 'pi pi-star-fill text-yellow-500' : 'pi pi-star text-gray-800'"
              (click)="toggleFavorite.emit({ itemId: item.id, isFavorite: !item.favorite })"
            ></button>
          </td>
          <td *ngIf="showEntityIcon">
            <i *ngIf="item.icon" [class]="item.icon + ' text-xl ' + item?.iconClass"></i>
          </td>
          <ng-container *ngFor="let column of columns">
            <td *ngIf="column.fieldCode === 'name'" class="py-0 {{ column.classStyle }}">
              <div *ngIf="editingName?.id !== item.id; else editNameForm" class="field-name-value align-items-center">
                <div class="name align-items-center gap-2">
                  <ng-container *ngIf="item.routerLinkToItem as routerLinkToItem">
                    <a
                      class="short-text"
                      [routerLink]="routerLinkToItem.routerLink"
                      [queryParams]="routerLinkToItem.queryParams"
                    >
                      {{ item.name }}
                    </a>
                  </ng-container>
                  <ng-container *ngIf="!item.routerLinkToItem">
                    {{ item.name }}
                  </ng-container>
                  <div class="flex align-items-center gap-1" *ngIf="item.labels?.length">
                    <ng-container *ngFor="let label of item.labels">
                      <button
                        *ngIf="label.action; else iconLabel"
                        type="button"
                        pButton
                        style="font-size: 1rem"
                        appearance="icon"
                        icon="{{ label.icon }}"
                        class="p-button-text p-button-link short-text"
                        tooltipPosition="top"
                        pTooltip="{{ label.tooltipTranslate | translate }}"
                        (click)="label.action($event.target)"
                      ></button>

                      <ng-template #iconLabel>
                        <i
                          class="{{ label.icon }}"
                          style="font-size: 1rem"
                          tooltipPosition="top"
                          pTooltip="{{ label.tooltipTranslate | translate }}"
                        ></i>
                      </ng-template>
                    </ng-container>
                  </div>
                </div>
                <button
                  *ngIf="isNameEditable"
                  (click)="startEditName(item)"
                  type="button"
                  pButton
                  class="field-name-value-button-edit p-button-secondary p-button-rounded p-0 ml-2"
                  appearance="icon"
                  icon="pi pi-pencil"
                  tooltipPosition="top"
                  pTooltip="{{ 'data-list-view.start-edit-name' | translate }}"
                ></button>
              </div>
            </td>
            <td *ngIf="column.fieldCode !== 'name'" class="{{ column.classStyle }}">
              <ng-container
                *ngIf="customFieldValueTemplates[column.fieldCode] as fieldValueTemplate; else commonFieldValue"
              >
                <ng-container
                  *ngTemplateOutlet="fieldValueTemplate.template; context: { $implicit: item }"
                ></ng-container>
              </ng-container>
              <ng-template #commonFieldValue>
                <ng-container *ngIf="column.fieldType === 'date'">
                  {{ item[column.fieldCode] | date: 'dd.MM.yyyy HH:mm' }}
                </ng-container>
                <ng-container *ngIf="column.fieldType !== 'date'">
                  {{ item[column.fieldCode] }}
                </ng-container>
              </ng-template>
            </td>
          </ng-container>

          <td class="short-text py-0" tabindex="-1">
            <div class="actions gap-2">
              <ng-container *ngFor="let action of getActionsForItem | executeWith: item | async">
                <button
                  type="button"
                  pButton
                  class="p-button-text p-button-link"
                  [ngClass]="{ 'p-button-danger': action.icon === 'pi pi-trash' }"
                  appearance="icon"
                  (click)="action.action($event.target)"
                  [icon]="action.icon"
                  tooltipPosition="top"
                  [disabled]="action.disabled"
                  pTooltip="{{ action.tooltipTranslate || action.labelTranslate | translate }}"
                ></button>
              </ng-container>
            </div>
          </td>
        </tr>
      </ng-template>

      <ng-template pTemplate="emptymessage">
        <tr>
          <td [colSpan]="columnsLength">
            {{ emptyMessage || ('data-list-view.empty-message' | translate) }}
          </td>
        </tr>
      </ng-template>

      <ng-template pTemplate="caption">
        <ng-container *ngTemplateOutlet="toolbar"></ng-container>
      </ng-template>

      <ng-template pTemplate="summary">
        <selfai-platform-data-list-view-pagination
          [pageNumber]="pageParams.pageNumber"
          [pageSize]="pageParams.pageSize"
          [totalRecords]="obs.totalRecords"
          (pageChange)="onPageChange($event)"
        ></selfai-platform-data-list-view-pagination>
      </ng-template>
    </p-table>
  </ng-container>
</ng-container>

<ng-template #editNameForm>
  <form class="flex align-items-center" (ngSubmit)="saveName()">
    <input
      class="w-full"
      pAutoFocus
      [autofocus]="true"
      [(ngModel)]="editingName.name"
      [ngModelOptions]="{ standalone: true }"
      pInputText
      type="text"
      placeholder="{{ 'msg.workbook-list.search.input-placeholder' | translate }}"
    />
    <button
      (click)="saveName()"
      type="button"
      pButton
      class="p-button-text p-button-link p-0 button-save-apply"
      appearance="icon"
      icon="pi pi-reply"
      tooltipPosition="top"
      pTooltip="{{ 'msg.workbook-list.save-name-edit-button-tooltip' | translate }}"
    ></button>
  </form>
</ng-template>
