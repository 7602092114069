export const MODELS_REGISTRY_TABLE_COLUMNS_CONFIG = [
  {
    labelTranslate: 'models-registry-list.table.columns.name',
    fieldCode: 'name',
    fieldType: 'text',
    classStyle: 'no-breaks short-text',
    width: '11rem',
    resizable: true,
    sortable: true,
  },
  {
    labelTranslate: 'models-registry-list.table.columns.latest-version',
    fieldCode: 'latestVersion',
    fieldType: 'custom',
    classStyle: 'no-breaks short-text',
    width: '12rem',
    resizable: true,
    sortable: true,
  },
  {
    labelTranslate: 'models-registry-list.table.columns.staging',
    fieldCode: 'stagingVersion',
    fieldType: 'custom',
    classStyle: 'no-breaks short-text',
    width: '12rem',
    resizable: true,
    sortable: true,
  },
  {
    labelTranslate: 'models-registry-list.table.columns.production',
    fieldCode: 'productionVersion',
    fieldType: 'custom',
    classStyle: 'no-breaks short-text',
    sortable: true,
    resizable: true,
    width: '12rem',
  },
  {
    labelTranslate: 'models-registry-list.table.columns.created',
    fieldCode: 'creation_timestamp',
    fieldType: 'date',
    classStyle: 'no-breaks short-text',
    sortable: true,
    resizable: true,
    width: '12rem',
  },
  {
    labelTranslate: 'models-registry-list.table.columns.last-modified',
    fieldCode: 'last_updated_timestamp',
    fieldType: 'date',
    classStyle: 'no-breaks short-text',
    sortable: true,
    resizable: true,
    width: '12rem',
  },
  {
    labelTranslate: 'models-registry-list.table.columns.tags',
    fieldCode: 'tags',
    fieldType: 'custom',
    classStyle: 'no-breaks short-text',
    sortable: true,
    resizable: true,
    width: '12rem',
  },
];

export const FILTER_FIELDS = ['name'];
