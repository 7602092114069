import {
  ExternalSqlQueryRawParameters,
  GraphNode,
  Parameter,
  SelectionColumnItem,
  SelectorParameter,
} from '@selfai-platform/pipeline-common';

export function extractSelectionItemsExternalSqlQuery(
  parameters: GraphNode<ExternalSqlQueryRawParameters>['parameters'],
): SelectionColumnItem[] | undefined {
  if (!parameters) {
    return undefined;
  }

  const parameter: SelectorParameter | undefined = parameters.parameters?.find((parameter) =>
    isSelectorParameter(parameter),
  ) as SelectorParameter;

  return parameter?.dataFrameSchema?.fields.map(({ name }, index) => ({ name, index }));
}

function isSelectorParameter(parameter: Parameter): parameter is SelectorParameter {
  return Object.prototype.hasOwnProperty.call(parameter as SelectorParameter, 'dataFrameSchema');
}
