import { Injectable } from '@angular/core';
import { OperationDetail, OperationsAdapter } from '@selfai-platform/pipeline-api';
import { GraphNodeOptionSerialized } from '@selfai-platform/pipeline-common';
import { AlertService, handleHttpErrorResponse } from '@selfai-platform/shared';
import { catchError, map, Observable, of, tap, throwError } from 'rxjs';
import { v4 as uuidv4 } from 'uuid';

@Injectable({
  providedIn: 'root',
})
export class OperationDetailService {
  private operationCacheMap = new Map<string, OperationDetail>();

  constructor(
    private readonly operationsAdapter: OperationsAdapter,
    private readonly alertService: AlertService,
  ) {}

  getWithLoading(operationId: string): Observable<OperationDetail> {
    if (this.operationCacheMap.has(operationId)) {
      return of(this.operationCacheMap.get(operationId) as OperationDetail);
    }

    return this.operationsAdapter.loadById(operationId).pipe(
      tap((operation) => {
        this.operationCacheMap.set(operationId, operation);
      }),
      catchError((e) => {
        const { text } = handleHttpErrorResponse(e);
        this.alertService.error(text);

        return throwError(() => e);
      }),
    );
  }

  getCubeByOperationId(operationId: string): Observable<GraphNodeOptionSerialized> {
    return this.getWithLoading(operationId).pipe(map(this.createCubeFromOperation.bind(this)));
  }

  private createCubeFromOperation(operation: OperationDetail): GraphNodeOptionSerialized {
    return {
      id: uuidv4(),
      uiName: '',
      color: '#00B1EB',
      parameters: {},
      operation: {
        id: operation.id,
        name: operation.name,
      },
    };
  }
}
