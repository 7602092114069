<div class="sticky pt-3 pb-3 px-0 bg-white z-1">
  <div class="p-3 align-items-center flex justify-content-between w-full">
    <span class="p-dialog-title">
      {{ header }}
      @if(cubeState$ | async; as cubeState) {
      <selfai-platform-error-button *ngIf="cubeState.error" [state]="cubeState"></selfai-platform-error-button>
      }
      <span class="node-id-text">{{ nodeId }}</span>
      <i class="pi pi-clone node-id-text-copy-button" (click)="copyIdToClipboard()"></i>
    </span>

    <div *ngIf="!isEmpty" class="p-dialog-header-icons gap-2">
      <ng-content select="[slot='button-pre']"></ng-content>
      <button
        type="button"
        class="mr-3 p-button-success"
        (click)="clickSave.emit()"
        (keydown.enter)="clickSave.emit()"
        [disabled]="!(workflowIsInEditMode | async) || saveButtonDisabled"
        tabindex="-1"
        pRipple
        pButton
        label="{{ 'workflow.cubes.dialog-header.save' | translate }}"
        icon="pi pi-save"
      ></button>
      <button
        *ngIf="dialogHelperService"
        type="button"
        [ngClass]="{ 'p-dialog-header-icon p-dialog-header-maximize p-link': true }"
        (click)="maximizeDialog()"
        (keydown.enter)="maximizeDialog()"
        tabindex="-1"
        pRipple
      >
        <span
          class="p-dialog-header-maximize-icon"
          [ngClass]="dialogHelperService.maximizedDialog ? 'pi pi-window-minimize' : 'pi pi-window-maximize'"
        ></span>
      </button>
      <ng-content select="[slot='button-post']"></ng-content>
      <p-button
        *ngIf="minimizeButtonShow"
        class="mr-2"
        icon="pi pi-window-minimize"
        [text]="true"
        (click)="minimize.emit(nodeId)"
      ></p-button>
      <ng-content select="[slot='button-post']"></ng-content>
      <button
        [ngClass]="'p-dialog-header-icon p-dialog-header-maximize p-link'"
        type="button"
        (click)="closeDialog.emit()"
        (keydown.enter)="closeDialog.emit()"
        tabindex="-1"
      >
        <span class="p-dialog-header-close-icon pi pi-times"></span>
      </button>
    </div>
    <button
      *ngIf="isEmpty"
      [ngClass]="'p-dialog-header-icon p-dialog-header-maximize p-link'"
      type="button"
      (click)="closeDialog.emit()"
      (keydown.enter)="closeDialog.emit()"
      tabindex="-1"
    >
      <span class="p-dialog-header-close-icon pi pi-times"></span>
    </button>
  </div>
</div>
