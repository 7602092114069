import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { DestroyService, downloadJson } from '@selfai-platform/shared';
import {
  DataListViewComponent,
  DataListViewTemplateNameDirective,
  GroupAction,
  provideDataListView,
  provideDialogService,
  SelectedItemsService,
} from '@selfai-platform/shell';
import { ConfirmationService } from 'primeng/api';
import { ButtonModule } from 'primeng/button';
import { DialogService } from 'primeng/dynamicdialog';
import { MenuModule } from 'primeng/menu';
import { MultiSelectChangeEvent, MultiSelectModule } from 'primeng/multiselect';
import { TagModule } from 'primeng/tag';
import { Observable, switchMap, takeUntil } from 'rxjs';
import { FunctionTag, UserFunctionApi, UserFunctionsListItem } from '../../models';
import {
  UserFunctionsActionsForItemService,
  UserFunctionsApiService,
  UserFunctionsDataListViewService,
  UserFunctionsPermissionService,
} from '../../services';
import { UserFunctionsTagsDomainService } from '../../services/user-functions-tags-domain.service';
import { UserFunctionsImportDialogComponent } from '../user-functions-import-dialog';

@Component({
  selector: 'selfai-platform-user-functions-list',
  templateUrl: './user-functions-list.component.html',
  styleUrls: ['./user-functions-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    CommonModule,
    TranslateModule,
    DataListViewComponent,
    MenuModule,
    ButtonModule,
    MultiSelectModule,
    FormsModule,
    DataListViewTemplateNameDirective,
    TagModule,
  ],
  providers: [
    DestroyService,
    ...provideDialogService(),
    ...provideDataListView(UserFunctionsDataListViewService, UserFunctionsActionsForItemService),
  ],
})
export class UserFunctionsListComponent implements OnInit {
  canCreate = this.userFunctionPermissionService.canCreateUserFunction();
  canUpload$ = this.userFunctionPermissionService.canUploadUserFunction();
  canExport$ = this.userFunctionPermissionService.canExportUserFunction();
  selectedTags: FunctionTag[];
  columns = this.userFunctionsDataListViewService.getColumns();
  groupActions$: Observable<GroupAction[]> = this.userFunctionsDataListViewService.getGroupActions();

  @Input() tags: FunctionTag[];

  constructor(
    private readonly userFunctionsDataListViewService: UserFunctionsDataListViewService,
    private readonly userFunctionsApiService: UserFunctionsApiService,
    private readonly userFunctionPermissionService: UserFunctionsPermissionService,
    private readonly selectedItemsService: SelectedItemsService<UserFunctionsListItem>,
    private readonly userFunctionsTagsDomainService: UserFunctionsTagsDomainService,
    private readonly confirmationService: ConfirmationService,
    private readonly dialogService: DialogService,
    private readonly translate: TranslateService,
    private readonly destroy$: DestroyService,
  ) {}

  ngOnInit(): void {
    this.userFunctionsDataListViewService.triggerReloadData();
  }

  onFilterByTags(event: MultiSelectChangeEvent): void {
    this.userFunctionsDataListViewService.filterData(event.value);
  }

  removeTag(tag: FunctionTag): void {
    this.confirmationService.confirm({
      key: 'dialog',
      message: `Do you really want to remove tag ${tag.name}?`,
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        if (tag.id) {
          this.userFunctionsTagsDomainService.deleteTag(tag.id);
        }
      },
    });
  }

  onClearTagsFilter(): void {
    this.userFunctionsDataListViewService.filterData([]);
  }

  importUserFunctions(): void {
    const dialogRef = this.dialogService.open(UserFunctionsImportDialogComponent, {
      header: this.translate.instant('user-functions.import-dialog.title'),
      width: '60vw',
      height: '65vh',
      modal: true,
    });
    dialogRef.onClose.pipe(takeUntil(this.destroy$)).subscribe((result) => {
      if (result) {
        this.userFunctionsDataListViewService.loadData();
      }
    });
  }

  exportUserFunction(): void {
    const sub = this.selectedItemsService
      .getSelectedItemIds()
      .pipe(switchMap((selectedItems) => this.userFunctionsApiService.exportUserFunctions(selectedItems)))
      .subscribe((data) => {
        downloadJson(data as UserFunctionApi[], 'functions.json');
        sub.unsubscribe();
      });
  }
}
