import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { FormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { PAGE_PARAMS_DEFAULT_PAGE_SIZE } from '@selfai-platform/shared';
import { DataListViewPaginationComponent, UI_SETTINGS_VIEW_PAGE_DEFAULT_SIZE } from '@selfai-platform/shell';
import { ConfirmationService, TreeNode } from 'primeng/api';
import { ButtonModule } from 'primeng/button';
import { ConfirmPopupModule } from 'primeng/confirmpopup';
import { DropdownModule } from 'primeng/dropdown';
import { FileUploadModule } from 'primeng/fileupload';
import { InputTextModule } from 'primeng/inputtext';
import { Table } from 'primeng/table';
import { TreeTableModule } from 'primeng/treetable';
import { DataSourcesLibraryFilesInterface } from '@selfai-platform/pipeline-api';

@Component({
  selector: 'selfai-platform-data-sources-library-data-frame-list',
  imports: [
    CommonModule,
    FormsModule,
    TranslateModule,
    TreeTableModule,
    InputTextModule,
    ButtonModule,
    FileUploadModule,
    ConfirmPopupModule,
    DropdownModule,
    DataListViewPaginationComponent,
  ],
  providers: [ConfirmationService],
  templateUrl: './data-sources-library-data-frame-list.component.html',
  styleUrls: ['./data-sources-library-data-frame-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DataSourcesLibraryDataFrameListComponent implements OnChanges {
  pageSize: number = UI_SETTINGS_VIEW_PAGE_DEFAULT_SIZE;
  pageSizes: number[] = [PAGE_PARAMS_DEFAULT_PAGE_SIZE, 24, 40, 120, 500];
  querySearch = '';
  pageNumber = 1;

  get totalRecords(): number {
    return this.normalizeLibraryFiles.length;
  }

  get first(): number {
    return this.pageSize * (this.pageNumber - 1);
  }

  public normalizeLibraryFiles: TreeNode[];

  @Input() libraries: DataSourcesLibraryFilesInterface[];
  @Output() selectEvent = new EventEmitter<string>();
  @Output() deleteEvent = new EventEmitter<string>();
  @Output() downloadEvent = new EventEmitter<string>();
  @Output() uploadEvent = new EventEmitter<{ files: File[] }>();
  @ViewChild('table') table: Table;

  constructor(
    private changeDetectorRef: ChangeDetectorRef,
    private confirmationService: ConfirmationService,
  ) {}

  onSearch(event: Event) {
    const { value } = event.target as HTMLInputElement;
    this.table.filterGlobal(value, 'contains');
  }

  onSelect(item: DataSourcesLibraryFilesInterface): void {
    this.selectEvent.emit(item.name);
  }

  onDelete(name: string): void {
    this.deleteEvent.emit(name);
  }

  onConfirmDelete(event: Event, item: DataSourcesLibraryFilesInterface): void {
    this.confirmationService.confirm({
      target: event.target,
      message: `Are you sure you want to delete the file ${item.name}?`,
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        this.onDelete(item.name);
      },
    });
  }

  onDownload(rowNode: IRowNode): void {
    if (!rowNode.parent) {
      this.downloadEvent.emit(rowNode.node.data.name);
    } else {
      this.downloadEvent.emit(this.getRootParentName(rowNode) + '/' + rowNode.node.data.name);
    }
  }

  onUpload(event: any): void {
    this.uploadEvent.emit(event);
  }

  onPageChange(event: { pageSize: number; pageNumber: number }): void {
    this.pageNumber = event.pageNumber;
  }

  public ngOnChanges(changes: SimpleChanges): void {
    this.normalizeLibraryFiles = this.convertLibrariesToNodes(this.libraries);
  }

  private convertLibrariesToNodes(childrens: DataSourcesLibraryFilesInterface[]): TreeNode[] {
    return childrens.map((children) => {
      const node = {
        data: { name: children.name, kind: children.kind },
        children: [] as TreeNode[],
      };
      if (children.hasOwnProperty('children') && children.children.length) {
        node.children = node.children.concat(this.convertLibrariesToNodes(children.children));
      }
      return node as TreeNode;
    });
  }

  private getRootParentName(rowNode: IRowNode | TreeNode): string {
    return !rowNode?.parent ? (rowNode as TreeNode).data.name : this.getRootParentName(rowNode.parent);
  }
}

interface IRowNode {
  parent: TreeNode;
  node: TreeNode;
}
