import { DragDropModule } from '@angular/cdk/drag-drop';
import { CommonModule } from '@angular/common';
import { provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { LocationStrategy, PathLocationStrategy } from '@angular/common';
import { RouterModule } from '@angular/router';
import { provideAggregatorApi, StepType } from '@selfai-platform/aggregator-api';
import { AggregatorDomainModule, provideStepsConfig } from '@selfai-platform/aggregator-domain';
import { aggregatorRouting } from '@selfai-platform/aggregator-ui';
import { BiModule } from '@selfai-platform/bi';
import { PageIsReadyComponent } from '@selfai-platform/bi-shared';
import { PipelineModuleModule, PipelineProviderModule } from '@selfai-platform/pipeline-module';
import { ConfigService, PACKAGE_VERSION, SELFAI_APP_BASE_HREF, SelfaiAppModules } from '@selfai-platform/shared';
import {
  HomePageWidgets,
  MenuLayoutClassDirective,
  notFoundRoute,
  ShellModule,
  UiLanguages,
} from '@selfai-platform/shell';
import { SelfaiStoreModule } from '@selfai-platform/store';
import packageJson from '../../package.json';
import { AppComponent } from './app.component';
import { mainMenuProviders } from './configs/main-menu-providers';

@NgModule({
  declarations: [AppComponent],
  bootstrap: [AppComponent],
  imports: [
    PageIsReadyComponent,
    DragDropModule,
    CommonModule,
    BrowserModule,
    SelfaiStoreModule,
    ShellModule.forRoot({
      homePageWidgets: [HomePageWidgets.SelfaiBi, HomePageWidgets.Pipeline],
      languages: [UiLanguages.EN, UiLanguages.RU],
      modules: [SelfaiAppModules.KD, SelfaiAppModules.KE],
    }),
    PipelineProviderModule.forRoot(),
    PipelineModuleModule,
    AggregatorDomainModule,
    BiModule,
    RouterModule.forRoot([...aggregatorRouting, notFoundRoute], {
      scrollPositionRestoration: 'enabled',
    }),
    MenuLayoutClassDirective,
  ],
  providers: [
    {
      provide: LocationStrategy,
      useClass: PathLocationStrategy,
    },
    {
      provide: SELFAI_APP_BASE_HREF,
      useFactory: setAppBasehref,
      deps: [LocationStrategy],
    },
    ConfigService,
    {
      provide: PACKAGE_VERSION,
      useValue: packageJson.version,
    },
    ...mainMenuProviders,
    provideAggregatorApi(),
    provideStepsConfig(Object.values(StepType)),
    provideHttpClient(withInterceptorsFromDi()),
  ],
})
export class AppModule {}

export function setAppBasehref(locStrategy: LocationStrategy) {
  return locStrategy.getBaseHref();
}
