import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FormFieldComponentModule, JsonPathFieldComponentModule } from '@selfai-platform/pipeline-shared';
import { ButtonModule } from 'primeng/button';
import { CardModule } from 'primeng/card';
import { CheckboxModule } from 'primeng/checkbox';
import { DropdownModule } from 'primeng/dropdown';
import { InputTextModule } from 'primeng/inputtext';
import { KeyFilterModule } from 'primeng/keyfilter';
import { MessagesModule } from 'primeng/messages';
import { TableModule } from 'primeng/table';
import { TooltipModule } from 'primeng/tooltip';
import { UsedInPanelComponent, UsedInPipe } from '../../../../common/used-in-panel';
import { EditSelectionFieldComponentModule } from '../../../../edit-selection-field/edit-selection-field.module';
import { JsonToColumnTransformationComponent } from './json-to-column-transformation.component';

@NgModule({
  imports: [
    CommonModule,
    TableModule,
    InputTextModule,
    TooltipModule,
    ReactiveFormsModule,
    ButtonModule,
    EditSelectionFieldComponentModule,
    FormsModule,
    DropdownModule,
    JsonPathFieldComponentModule,
    CardModule,
    FormFieldComponentModule,
    CheckboxModule,
    MessagesModule,
    KeyFilterModule,
    UsedInPanelComponent,
    UsedInPipe,
  ],
  declarations: [JsonToColumnTransformationComponent],
  exports: [JsonToColumnTransformationComponent],
})
export class JsonToColumnTransformationComponentModule {}
