import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { DestroyService } from '@selfai-platform/shared';
import {
  BreadcrumbsMenuItem,
  BreadcrumbsMenuService,
  KE_DATASOURCES_PATH,
  KE_ROOT_ROUTE,
} from '@selfai-platform/shell';
import { map, Observable, takeUntil } from 'rxjs';
import { KeCurrentUserDomainService } from '../../../current-user';
import { DataSourceListComponent } from '../../components/data-source-list';
@Component({
  selector: 'selfai-platform-data-sources-list-container',
  imports: [CommonModule, DataSourceListComponent],
  providers: [DestroyService],
  templateUrl: './data-sources-list-container.component.html',
  styleUrls: ['./data-sources-list-container.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DataSourcesListContainerComponent implements OnInit {
  breadcrumbItems$: Observable<BreadcrumbsMenuItem[]> = this.translate.stream('shell.menu.data-sources').pipe(
    map((label) => [
      {
        label,
        routerLink: ['/', KE_ROOT_ROUTE, KE_DATASOURCES_PATH],
      },
    ]),
  );

  constructor(
    private breadcrumbsMenuService: BreadcrumbsMenuService,
    private translate: TranslateService,
    private destroy$: DestroyService,
    private keCurrentUserDomainService: KeCurrentUserDomainService,
  ) {}

  ngOnInit(): void {
    this.keCurrentUserDomainService.loadProfileIfEmpty();

    this.breadcrumbItems$.pipe(takeUntil(this.destroy$)).subscribe((breadcrumbItems) => {
      this.breadcrumbsMenuService.setBreadcrumbsMenu(breadcrumbItems);
    });
  }
}
