import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Condition, ConditionFormGroup, CubeEditResult, CubeWorkflowData } from '@selfai-platform/pipeline-common';
import { DialogService } from '@selfai-platform/shell';
import { Observable } from 'rxjs';
import { WorkflowReportDomainService } from '../../../wokflow-list';
import { CodeLanguage } from '@selfai-platform/pipeline-shared';

@Component({
  selector: 'selfai-platform-condition',
  templateUrl: './condition-edit.component.html',
  styleUrls: ['./condition-edit.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false,
})
export class ConditionEditComponent {
  @Input() conditionItems!: Condition[];
  @Input() form!: FormGroup<ConditionFormGroup>;

  conditionType = Condition;
  codeLanguage = CodeLanguage;

  fieldsFromExecutionReport$: Observable<string[]> = this.workflowReportDomainService.getFieldsFromExecutionReport(
    this.dialogService.data.selectedNode.id,
  );

  constructor(
    private readonly dialogService: DialogService<CubeEditResult, CubeWorkflowData>,
    private readonly workflowReportDomainService: WorkflowReportDomainService,
  ) {}
}
