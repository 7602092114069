import { Injectable } from '@angular/core';
import {
  convertMessageToHtml,
  DataListViewComponentService,
  GroupAction,
  KE_MODELS_REGISTRY_PATH,
  KE_ROOT_ROUTE,
  MenuItemsNormalizerService,
  SelectedItemsService,
  TableColumn,
} from '@selfai-platform/shell';
import {
  EModelsRegistryVersionCurrentStage,
  IModelsRegistryModelVersion,
  ModelsRegistryListItem,
} from '@selfai-platform/pipeline-api';
import { ConfirmationService } from 'primeng/api';
import { TranslateService } from '@ngx-translate/core';
import { Router } from '@angular/router';
import { ModelsRegistryPermissionAction, UrlPageParamsService } from '@selfai-platform/shared';
import { combineLatest, map, Observable, take } from 'rxjs';
import { ModelsRegistryListDomainService } from './models-registry-list-domain.service';
import { ModelsRegistryListStore } from '../store';
import { ModelsRegistryPermissionService } from './models-registry-permission.service';
import { MODELS_REGISTRY_TABLE_COLUMNS_CONFIG } from '../constants';

@Injectable({
  providedIn: 'root',
})
export class ModelsRegistryDataListViewService extends DataListViewComponentService<ModelsRegistryListItem> {
  constructor(
    private readonly modelsRegistryListDomainService: ModelsRegistryListDomainService,
    private readonly modelsRegistryListStore: ModelsRegistryListStore,
    private readonly modelsRegistryPermissionService: ModelsRegistryPermissionService,
    private readonly confirmationService: ConfirmationService,
    private readonly translate: TranslateService,
    private readonly router: Router,
    private readonly menuItemsNormalizerService: MenuItemsNormalizerService,
    urlPageParamsService: UrlPageParamsService,
    selectedItemService: SelectedItemsService<ModelsRegistryListItem>,
  ) {
    super(urlPageParamsService, selectedItemService);
  }

  override loadData(): Observable<{ items: ModelsRegistryListItem[]; totalItems: number }> {
    return this.modelsRegistryListDomainService.loadModelsList().pipe(
      map(({ models }) => ({
        items: models,
        totalItems: models.length,
      })),
    );
  }

  override getData(): Observable<ModelsRegistryListItem[]> {
    return combineLatest({
      models: this.modelsRegistryListDomainService.getModelsList(),
      modelsRegistryPermissionGet: this.modelsRegistryPermissionService.checkPermission(
        ModelsRegistryPermissionAction.Get,
      ),
      modelsRegistryPermissionUpdate: this.modelsRegistryPermissionService.checkPermission(
        ModelsRegistryPermissionAction.Update,
      ),
    }).pipe(map(({ models, ...permissions }) => models.map((item) => this.normalizeForView(item, { ...permissions }))));
  }

  override isLoading(): Observable<boolean> {
    return this.modelsRegistryListDomainService.getModelsListLoading();
  }

  override isLoaded(): Observable<boolean> {
    return this.modelsRegistryListDomainService.getModelsListLoaded();
  }

  override hasError(): Observable<boolean> {
    return this.modelsRegistryListDomainService.getModelsListError().pipe(map((error) => !!error));
  }

  public getColumns(): TableColumn<ModelsRegistryListItem>[] {
    return MODELS_REGISTRY_TABLE_COLUMNS_CONFIG as TableColumn<ModelsRegistryListItem>[];
  }

  public getGroupActions(): Observable<GroupAction[]> {
    return this.modelsRegistryPermissionService.checkPermission(ModelsRegistryPermissionAction.Delete).pipe(
      map((permission) => {
        if (permission) {
          return [
            {
              tooltipTranslate: 'models-registry-list.toolbar.actions.delete-selected',
              icon: 'pi pi-trash',
              buttonClass: 'p-button-danger',
              action: () => this.deleteSelected(),
            },
          ];
        } else {
          return [];
        }
      }),
    );
  }

  private deleteSelected(): void {
    this.confirmationService.confirm({
      key: 'dialog',
      message: convertMessageToHtml(
        this.translate.instant('models-registry-list.toolbar.actions.delete-selected.confirmation'),
      ),
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        this.selectedItemsService
          .getSelectedItemIds()
          .pipe(take(1))
          .subscribe((ids) => {
            ids.forEach((id) => {
              this.modelsRegistryListDomainService.deleteModel(id);
            });
          });
      },
    });
  }

  private normalizeForView(
    model: ModelsRegistryListItem,
    permissions: Record<string, boolean>,
  ): ModelsRegistryListItem {
    let normalized = {
      ...model,
      icon: 'pi pi-microchip-ai',
      iconClass: 'text-indigo-500',
      stagingVersion: this.getVersionName(model.latest_versions, EModelsRegistryVersionCurrentStage.Staging),
      productionVersion: this.getVersionName(model.latest_versions, EModelsRegistryVersionCurrentStage.Production),
      latestVersion: this.getVersionName(model.latest_versions, EModelsRegistryVersionCurrentStage.None, true),
    };

    if (permissions?.['modelsRegistryPermissionGet']) {
      normalized = {
        ...normalized,
        routerLinkToItem: {
          routerLink: ['/', KE_ROOT_ROUTE, KE_MODELS_REGISTRY_PATH, 'edit', model.id],
        },
      };
    }
    return normalized;
  }

  private getVersionName(
    versions: IModelsRegistryModelVersion[] | undefined,
    stage: EModelsRegistryVersionCurrentStage,
    latest?: boolean,
  ): string {
    if (!versions) {
      return '-';
    }

    if (latest) {
      return 'Version ' + versions.reduce((a, b) => (a.creation_timestamp > b.creation_timestamp ? a : b)).version;
    }

    const version = versions.find((v) => v.current_stage === stage);
    return version ? 'Version ' + version.version : '-';
  }
}
