import { Injectable } from '@angular/core';
import { Observable, map } from 'rxjs';
import { DataSourceApi } from '@selfai-platform/pipeline-api';
import { DataSourceListStore } from '../store';
import { DataSourceList } from '../model/data-source.model';

@Injectable({ providedIn: 'root' })
export class DataSourceListDomainService {
  constructor(private dataSourceListStore: DataSourceListStore) {}

  getDataSourceList(): Observable<DataSourceApi[]> {
    return this.dataSourceListStore.entities$;
  }

  loadDataSourceList(): Observable<DataSourceList> {
    return this.dataSourceListStore.getAll().pipe(map((dataSources) => ({ dataSources })));
  }

  getDataSourceListLoading(): Observable<boolean> {
    return this.dataSourceListStore.loading$;
  }

  getDataSourceListError(): Observable<unknown> {
    return this.dataSourceListStore.errors$;
  }

  getDataSourceListLoaded(): Observable<boolean> {
    return this.dataSourceListStore.loaded$;
  }

  deleteDataSource(id: string): Observable<void> {
    return this.dataSourceListStore.delete(id).pipe(map(() => void 0));
  }
}
