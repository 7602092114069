import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { CodeEditorComponentModule } from '@selfai-platform/pipeline-shared';
import { MessageModule } from 'primeng/message';
import { SkeletonModule } from 'primeng/skeleton';
import { CodeEditorTransformationComponent } from './code-editor-transformation.component';

@NgModule({
  imports: [CommonModule, FormsModule, CodeEditorComponentModule, MessageModule, SkeletonModule],
  declarations: [CodeEditorTransformationComponent],
  exports: [CodeEditorTransformationComponent],
})
export class CodeEditorTransformationComponentModule {}
