import { Injectable } from '@angular/core';
import {
  convertMessageToHtml,
  DataListViewComponentService,
  GroupAction,
  KE_MODELS_REGISTRY_VERSIONS_PATH,
  MenuItemsNormalizerService,
  SelectedItemsService,
  TableColumn,
} from '@selfai-platform/shell';
import { ModelsRegistryVersionsListItem } from '@selfai-platform/pipeline-api';
import { ConfirmationService } from 'primeng/api';
import { TranslateService } from '@ngx-translate/core';
import { Router } from '@angular/router';
import { DestroyService, ModelsRegistryPermissionAction, UrlPageParamsService } from '@selfai-platform/shared';
import { combineLatest, map, Observable, take, takeUntil } from 'rxjs';
import { ModelsRegistryVersionsListStore } from '../store';
import { ModelsRegistryPermissionService } from './models-registry-permission.service';
import { MODELS_REGISTRY_VERSIONS_TABLE_COLUMNS_CONFIG } from '../constants';
import { ModelsRegistryVersionsListDomainService } from './models-registry-versions-list-domain.service';

@Injectable({
  providedIn: 'root',
})
export class ModelsRegistryVersionsDataListViewService extends DataListViewComponentService<ModelsRegistryVersionsListItem> {
  constructor(
    private readonly modelsRegistryVersionsListDomainService: ModelsRegistryVersionsListDomainService,
    private readonly modelsRegistryVersionsListStore: ModelsRegistryVersionsListStore,
    private readonly modelsRegistryPermissionService: ModelsRegistryPermissionService,
    private readonly confirmationService: ConfirmationService,
    private readonly translate: TranslateService,
    private readonly router: Router,
    private readonly menuItemsNormalizerService: MenuItemsNormalizerService,
    private readonly destroy$: DestroyService,
    urlPageParamsService: UrlPageParamsService,
    selectedItemService: SelectedItemsService<ModelsRegistryVersionsListItem>,
  ) {
    super(urlPageParamsService, selectedItemService);
  }

  override loadData(): Observable<{ items: ModelsRegistryVersionsListItem[]; totalItems: number }> {
    return this.modelsRegistryVersionsListDomainService.loadModelVersionsList().pipe(
      map(({ models }) => ({
        items: models,
        totalItems: models.length,
      })),
    );
  }

  override getData(): Observable<ModelsRegistryVersionsListItem[]> {
    return combineLatest({
      models: this.modelsRegistryVersionsListDomainService.getModelVersionsList(),
      modelsRegistryPermissionGet: this.modelsRegistryPermissionService.checkPermission(
        ModelsRegistryPermissionAction.Get,
      ),
      modelsRegistryPermissionUpdate: this.modelsRegistryPermissionService.checkPermission(
        ModelsRegistryPermissionAction.Update,
      ),
    }).pipe(
      takeUntil(this.destroy$),
      map(({ models, ...permissions }) => models.map((item) => this.normalizeForView(item, { ...permissions }))),
    );
  }

  override isLoading(): Observable<boolean> {
    return this.modelsRegistryVersionsListDomainService.getModelVersionsListLoading();
  }

  override isLoaded(): Observable<boolean> {
    return this.modelsRegistryVersionsListDomainService.getModelVersionsListLoaded();
  }

  override hasError(): Observable<boolean> {
    return this.modelsRegistryVersionsListDomainService.getModelVersionsListError().pipe(map((error) => !!error));
  }

  public getColumns(): TableColumn<ModelsRegistryVersionsListItem>[] {
    return MODELS_REGISTRY_VERSIONS_TABLE_COLUMNS_CONFIG as TableColumn<ModelsRegistryVersionsListItem>[];
  }

  public getGroupActions(): Observable<GroupAction[]> {
    return this.modelsRegistryPermissionService.checkPermission(ModelsRegistryPermissionAction.Delete).pipe(
      map((permission) => {
        if (permission) {
          return [
            {
              tooltipTranslate: 'models-registry-list.toolbar.actions.delete-selected',
              icon: 'pi pi-trash',
              buttonClass: 'p-button-danger',
              action: () => this.deleteSelected(),
            },
          ];
        } else {
          return [];
        }
      }),
    );
  }

  private deleteSelected(): void {
    this.confirmationService.confirm({
      key: 'dialog',
      message: convertMessageToHtml(
        this.translate.instant('models-registry-list.toolbar.actions.delete-selected.confirmation'),
      ),
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        this.selectedItemsService
          .getSelectedItemIds()
          .pipe(take(1))
          .subscribe((ids) => {
            ids.forEach((id) => {
              this.modelsRegistryVersionsListDomainService.deleteModelVersion(id);
            });
          });
      },
    });
  }

  private normalizeForView(
    version: ModelsRegistryVersionsListItem,
    permissions: Record<string, boolean>,
  ): ModelsRegistryVersionsListItem {
    let normalized = {
      ...version,
      icon: 'pi pi-verified',
      iconClass: 'text-teal-500',
      name: 'Version ' + version.version,
    };

    if (permissions?.['modelsRegistryPermissionGet']) {
      normalized = {
        ...normalized,
        routerLinkToItem: {
          routerLink: ['./', KE_MODELS_REGISTRY_VERSIONS_PATH, version.version],
        },
      };
    }
    return normalized;
  }
}
