import { FieldType, JsonPathApiModel } from '@selfai-platform/pipeline-shared';
import { JsonPathFieldValue } from '../models';

export const JSON_PATH_EMPTY_VALUE: JsonPathApiModel = {
  jsonpath: '',
} as const;

export const JSON_PATH_INIT_FORM_VALUE: JsonPathFieldValue = {
  type: FieldType.JSONPATH,
  value: '',
} as const;
