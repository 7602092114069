import { Injectable } from '@angular/core';
import { WorkflowStateService } from '@selfai-platform/storage';
import { filter, map, Observable, switchMap } from 'rxjs';
import {
  extractColumnsDataFromExecutionReport,
  extractDataSampleFromExecutionReport,
} from '../../../utils/extract-table-from-execution-report';
import { WorkflowState } from '../../stores';
import { WorkflowDomainService } from './workflow-domain.service';

@Injectable({ providedIn: 'root' })
export class WorkflowReportDomainService {
  constructor(
    private readonly workflowDomainService: WorkflowDomainService,
    private readonly workflowStateService: WorkflowStateService,
  ) {}

  getExecutionReport(workflowId: string): Observable<WorkflowState['executionReport']> {
    return this.workflowDomainService.getWorkflow(workflowId).pipe(
      filter(Boolean),
      map((workflow) => workflow.executionReport),
    );
  }

  loadExecutionReport(workflowId: string): Observable<WorkflowState['executionReport']> {
    return this.workflowDomainService.loadWorkflow(workflowId).pipe(map((workflow) => workflow.executionReport));
  }

  loadExecutionReportForCurrentWorkflow(): Observable<WorkflowState['executionReport']> {
    return this.workflowStateService.getWorkflowState().pipe(
      filter(Boolean),
      switchMap((workflowState) => {
        return this.loadExecutionReport(workflowState.id);
      }),
      filter(Boolean),
    );
  }

  getFieldsFromExecutionReport(nodeId: string): Observable<string[]> {
    return this.workflowStateService.getWorkflowState().pipe(
      filter(Boolean),
      switchMap((workflowState) => {
        return this.loadExecutionReport(workflowState.id);
      }),
      filter(Boolean),
      map((executionReport) => {
        return executionReport
          ? extractDataSampleFromExecutionReport(nodeId, executionReport)?.columnNames ||
              extractColumnsDataFromExecutionReport(nodeId, executionReport)?.map(({ columnName }) => columnName)
          : [];
      }),
    );
  }

  getExecutionReportLoading(): Observable<boolean> {
    return this.workflowDomainService.getWorkflowLoading();
  }

  getExecutionReportError(): Observable<unknown> {
    return this.workflowDomainService.getWorkflowError();
  }

  getExecutionReportSuccess(): Observable<boolean> {
    return this.workflowDomainService.getWorkflowSuccess();
  }
}
