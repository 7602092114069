import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { ModelComponent } from '../../components';
import { AsyncPipe } from '@angular/common';
import { UserFunctionComponent } from '../../../user-functions/components';
import { Observable } from 'rxjs';
import { ActivatedRoute } from '@angular/router';
import { HistoryBackService } from '@selfai-platform/shared';
import { KE_MODELS_REGISTRY_PATH, KE_ROOT_ROUTE, KE_USER_FUNCTIONS_PATH } from '@selfai-platform/shell';
import { ModelsRegistryListStore } from '../../store';
import { ModelsRegistryListItem } from '@selfai-platform/pipeline-api';

@Component({
  selector: 'selfai-platform-model-container',
  templateUrl: './model-container.component.html',
  styleUrls: ['./model-container.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [ModelComponent, AsyncPipe, UserFunctionComponent],
})
export class ModelContainerComponent implements OnInit {
  public models$: Observable<ModelsRegistryListItem[]> = this.modelsRegistryListStore.entities$ as Observable<
    ModelsRegistryListItem[]
  >;
  public loading$: Observable<boolean> = this.modelsRegistryListStore.loading$ as Observable<boolean>;
  public id: string = this.route?.snapshot?.params['modelId'];
  public editMode = !!this.id;

  constructor(
    private readonly route: ActivatedRoute,
    private readonly modelsRegistryListStore: ModelsRegistryListStore,
    private readonly history: HistoryBackService,
  ) {}

  public ngOnInit(): void {
    if (this.editMode) {
      this.modelsRegistryListStore.getByKey(this.id);
    }
  }

  public onSubmit(model: ModelsRegistryListItem): void {
    if (this.editMode) {
      this.modelsRegistryListStore.update(model);
    } else {
      this.modelsRegistryListStore.add(model);
    }
  }

  public onBack(): void {
    this.history.back(['/', KE_ROOT_ROUTE, KE_MODELS_REGISTRY_PATH]);
  }
}
