import { Injectable } from '@angular/core';

import { DialogService } from '@selfai-platform/shell';
import { from, Observable, switchMap } from 'rxjs';
import { TreeNode } from './tree.model';

@Injectable({
  providedIn: 'root',
})
export class JsonSchemaTreeViewService {
  constructor(private readonly dialogService: DialogService<TreeNode>) {}

  showJsonTreeSchemaView(): Observable<TreeNode> {
    return from(import('./json-schema-tree-view.component')).pipe(
      switchMap((m) =>
        this.dialogService.showDialog(m.JsonSchemaTreeViewComponent, {
          width: '95%',
        }),
      ),
    );
  }
}
