<form [formGroup]="form" (ngSubmit)="submit()">
  <div class="p-float-label mt-4">
    <input pAutoFocus [autofocus]="true" class="w-full" formControlName="name" pInputText type="text" inputId="name" />
    <label for="name">{{ 'workflow-list.dialog.add-workflow.name' | translate }}</label>
  </div>
  <div class="p-float-label mt-5">
    <input class="w-full" formControlName="description" pInputText type="text" inputId="description" />
    <label for="description">{{ 'workflow-list.dialog.add-workflow.description' | translate }}</label>
  </div>

  <div class="field-checkbox mt-5">
    <p-checkbox name="input" [binary]="true" formControlName="isPublic" inputId="is-public"></p-checkbox>
    <label for="is-public">{{ 'workflow-list.dialog.add-workflow.is-public' | translate }}</label>
  </div>

  <div class="mt-5">
    <button
      type="button"
      class="mr-3 p-button-secondary p-button-outlined"
      (click)="closeDialog()"
      (keydown.enter)="closeDialog()"
      pRipple
      pButton
      label="{{ 'workflow-list.dialog.add-workflow.close' | translate }}"
    ></button>

    <button
      type="submit"
      class="mr-3 p-button-success"
      pRipple
      pButton
      [disabled]="!form.get('name')?.value"
      label="{{ 'workflow-list.dialog.add-workflow.create' | translate }}"
    ></button>
  </div>
</form>
