import {
  Component,
  ElementRef,
  EventEmitter,
  Injector,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';

import { saveAs } from 'file-saver';

import { QueryParam, SearchQueryRequest, getDownloadFilters } from '@selfai-platform/bi-domain';

import { WidgetService } from '../../../dashboard/service/widget.service';
import { DatasourceService } from '../../../datasource/service/datasource.service';
import { CommonUtil } from '../../util/common.util';
import { AbstractPopupComponent } from '../abstract-popup.component';
import { GridComponent } from '../grid/grid.component';

@Component({
  selector: 'data-download',
  templateUrl: './data.download.component.html',
  host: { '(document:click)': 'onClickHost($event)' },
  standalone: false,
})
export class DataDownloadComponent extends AbstractPopupComponent implements OnInit, OnChanges, OnDestroy {
  private _downloadId = '';

  private _gridComp: GridComponent;

  private _downData: { cols: any[]; rows: any[] };

  private _queryParams: QueryParam;

  public mode: 'WIDGET' | 'GRID' | 'DATA' = 'WIDGET';

  public isShow = false;

  public isOriginDown = true;

  public downloadType = 'ALL';
  public downloadRow = 0;
  public preview: PreviewResult;

  public commonUtil = CommonUtil;

  @Input()
  public title = '';

  @Input()
  public query: SearchQueryRequest = null;

  @Output('close')
  public closeEvent: EventEmitter<any> = new EventEmitter<any>();

  @Output('startDownload')
  public startDownEvent: EventEmitter<any> = new EventEmitter();

  @Output('endDownload')
  public endDownEvent: EventEmitter<any> = new EventEmitter();

  constructor(
    private datasourceService: DatasourceService,
    private widgetService: WidgetService,

    protected elementRef: ElementRef,
    protected injector: Injector,
  ) {
    super(elementRef, injector);
  }

  public ngOnInit() {
    super.ngOnInit();
  }

  public ngOnChanges(changes: SimpleChanges) {}

  public ngOnDestroy() {
    super.ngOnDestroy();
  }

  public onClickHost(event: MouseEvent) {
    const $target: any = $(event.target);
    if (
      this.isShow &&
      !$target.hasClass('ddp-icon-widget-gridsave') &&
      !$target.hasClass('ddp-box-layout4') &&
      0 === $target.closest('.ddp-box-layout4').length
    ) {
      this.close();
    }
  }

  public openWidgetDown(
    event: MouseEvent,
    widgetId: string,
    isOriginDown: boolean = true,
    query: SearchQueryRequest = null,
  ) {
    this._openComponent(event, 'RIGHT');
    this._downloadId = widgetId;
    this.mode = 'WIDGET';
    this.isOriginDown = isOriginDown;
    this.preview = undefined;
    this.query = query;

    let param = null;
    if (query) {
      param = getDownloadFilters(query);
    }
  }

  public openGridDown(event: MouseEvent, gridComp: GridComponent, preview?: PreviewResult) {
    this._openComponent(event, 'RIGHT');
    preview && (this.preview = preview);
    this.mode = 'GRID';
    this.isOriginDown = true;
    this._gridComp = gridComp;
  }

  public openDataDown(event: MouseEvent, cols: any[], rows: any[], preview: PreviewResult, queryParam?: QueryParam) {
    this._openComponent(event, 'RIGHT');
    this.preview = preview;
    this.mode = 'DATA';
    this.isOriginDown = true;
    this._downData = { cols: cols, rows: rows };
    queryParam && (this._queryParams = queryParam);
  }

  public close() {
    this.isShow = false;
    this.closeEvent.emit();
  }

  public downloadCSV() {
    this.close();
    if ('WIDGET' === this.mode) {
      this.startDownEvent.emit();
      let param = [];
      if (this.query) {
        param = getDownloadFilters(this.query);
      }

      this.widgetService.downloadWidget(this._downloadId, this.isOriginDown, 1000000, 'CSV', param).subscribe(
        (result) => {
          saveAs(result, 'data.csv');
          this.endDownEvent.emit();
        },
        () => {
          this.alertPrimeService.error(this.translateService.instant('msg.comm.alert.save.fail'));
          this.endDownEvent.emit();
        },
      );
    } else if ('GRID' === this.mode) {
      this._gridComp.csvDownload('data');
    } else if ('DATA' === this.mode) {
      if (!this._downData.rows && this._queryParams) {
        this.pageLoaderService.show();
        this.datasourceService
          .getDatasourceQuery(this._queryParams)
          .then((downData) => {
            this._dataDownload(this._downData.cols, downData, 'CSV');
            this.pageLoaderService.hide();
          })
          .catch(() => {
            this.pageLoaderService.hide();
          });
      }
    }
  }

  public downloadExcel() {
    this.close();
    if ('WIDGET' === this.mode) {
      this.startDownEvent.emit();
      let param = null;
      if (this.query) {
        param = getDownloadFilters(this.query);
      }
      this.widgetService.downloadWidget(this._downloadId, this.isOriginDown, 1000000, 'EXCEL', param).subscribe(
        (result) => {
          saveAs(result, 'data.xlsx');
          this.endDownEvent.emit();
        },
        () => {
          this.alertPrimeService.error(this.translateService.instant('msg.comm.alert.save.fail'));
          this.endDownEvent.emit();
        },
      );
    } else if ('GRID' === this.mode) {
      this._gridComp.excelDownload('data');
    } else if ('DATA' === this.mode) {
      if (!this._downData.rows && this._queryParams) {
        this.pageLoaderService.show();
        this.datasourceService
          .getDatasourceQuery(this._queryParams)
          .then((downData) => {
            this._dataDownload(this._downData.cols, downData, 'EXCEL');
            this.pageLoaderService.hide();
          })
          .catch(() => {
            this.pageLoaderService.hide();
          });
      } else {
        this._dataDownload(this._downData.cols, this._downData.rows, 'EXCEL');
      }
    }
  }

  private _openComponent(event: MouseEvent, position: string) {
    this.downloadType = 'ALL';
    this.downloadRow = 0;
    this.isShow = true;
    this.safelyDetectChanges();

    let $target: any = $(event.target);
    $target.hasClass('ddp-box-btn2') || ($target = $target.closest('.ddp-box-btn2'));
    const lnbmoreLeft: number = $target.offset().left;
    const lnbmoreTop: number = $target.offset().top;
    switch (position) {
      case 'RIGHT':
        this.$element.find('.ddp-box-layout4.ddp-download').css({ left: lnbmoreLeft - 340, top: lnbmoreTop + 20 });
        break;
      case 'CENTER':
        this.$element.find('.ddp-box-layout4.ddp-download').css({ left: lnbmoreLeft - 170, top: lnbmoreTop + 20 });
        break;
      case 'LEFT':
        this.$element.find('.ddp-box-layout4.ddp-download').css({ left: lnbmoreLeft, top: lnbmoreTop + 20 });
        break;
      default:
    }
  }

  private _dataDownload(cols: any[], rows: any[], type: 'CSV' | 'EXCEL', fileName: string = 'data'): void {
    if (cols && rows) {
      let downRows: any[] = [];

      const header: any[] = cols.map((col) => '"' + col.name + '"');
      downRows.push(header.join(','));

      downRows = downRows.concat(
        rows.map((row) => {
          const obj: any[] = [];
          cols.forEach((col) => {
            obj.push('"' + row[col.name] + '"');
          });
          return obj.join(',');
        }),
      );

      if ('CSV' === type) {
        saveAs(
          new Blob(['\ufeff' + downRows.join('\n')], { type: 'application/csv;charset=utf-8' }),
          fileName + '.csv',
        );
      } else {
        saveAs(
          new Blob(['\ufeff' + downRows.join('\n')], { type: 'application/vnd.ms-excel;charset=charset=utf-8' }),
          fileName + '.xls',
        );
      }
    }
  }
}

export class PreviewResult {
  public size: number;
  public count: number;

  constructor(size: number, count: number) {
    this.size = size ? size : 0;
    this.count = count ? count : 0;
  }
}
