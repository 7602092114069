import { ChangeDetectionStrategy, Component } from '@angular/core';
import { Observable } from 'rxjs';
import { ModelsRegistryVersionsListItem } from '@selfai-platform/pipeline-api';
import { ActivatedRoute } from '@angular/router';
import { ModelsRegistryVersionsListStore } from '../../store';
import { HistoryBackService } from '@selfai-platform/shared';
import { ModelVersionComponent } from '../../components';
import { AsyncPipe } from '@angular/common';
import { KE_MODELS_REGISTRY_PATH, KE_ROOT_ROUTE } from '@selfai-platform/shell';

@Component({
  selector: 'selfai-platform-model-version-container',
  templateUrl: './model-version-container.component.html',
  styleUrls: ['./model-version-container.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [ModelVersionComponent, AsyncPipe],
})
export class ModelVersionContainerComponent {
  public versions$: Observable<ModelsRegistryVersionsListItem[]> = this.modelsRegistryVersionsListStore
    .entities$ as Observable<ModelsRegistryVersionsListItem[]>;
  public loading$: Observable<boolean> = this.modelsRegistryVersionsListStore.loading$ as Observable<boolean>;
  public id: string = this.route?.snapshot?.params['versionId'];
  public modelId = this.route?.snapshot?.params['modelId'];
  public editMode = !!this.id;

  constructor(
    private readonly route: ActivatedRoute,
    private readonly modelsRegistryVersionsListStore: ModelsRegistryVersionsListStore,
    private readonly history: HistoryBackService,
  ) {}

  public onSubmit(version: ModelsRegistryVersionsListItem): void {
    if (this.editMode) {
      this.modelsRegistryVersionsListStore.update(version);
    } else {
      this.modelsRegistryVersionsListStore.add(version);
    }
  }

  public onBack(): void {
    this.history.back(['/', KE_ROOT_ROUTE, KE_MODELS_REGISTRY_PATH, 'edit', this.modelId]);
  }
}
