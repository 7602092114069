import { PipelineConfig } from './pipeline-config.model';

export const PIPELINE_DEFAULT_CONFIG: Partial<PipelineConfig> = {
  socket: {
    protocol: 'amqp',
    enableClientLogging: false,
    reconnectionInterval: 1000,
    mqPrefix: 'bda-we',
  },
  features: {
    aggregation: {
      enabled: false,
    },
    expiremental: {
      enabled: false,
    },
    variables: {
      enabled: false,
    },
    versions: {
      enabled: false,
    },
    library: {
      enabled: true,
    },
    datasources: {
      enabled: true,
    },
    workflows: {
      enabled: true,
    },
    functions: {
      enabled: false,
    },
    scheduling: {
      enabled: false,
    },
    presets: {
      enabled: false,
    },
    modelsRegistry: {
      enabled: false,
    },
  },
  versions: {
    api: '1.4.0',
    editor: '1.4.0',
    url: 'v1',
  },
  sessions: {
    pollingInterval: 10000,
  },
  libraryPrefix: 'library://',
} as const;
