import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { AsyncPipe, JsonPipe, NgForOf, NgIf } from '@angular/common';
import { TableModule } from 'primeng/table';
import { ArrayType, ColumnSpec, ColumnType, ObjectType, TensorSpec } from '@selfai-platform/pipeline-api';
import { TranslateModule } from '@ngx-translate/core';

export const INDENTATION_SPACES = 2;

@Component({
  selector: 'selfai-platform-model-schema',
  templateUrl: './model-schema.component.html',
  styleUrls: ['./model-schema.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [AsyncPipe, JsonPipe, NgForOf, TableModule, NgIf, TranslateModule],
})
export class ModelSchemaComponent {
  @Input() signature: Record<string, string>;

  public parseInputs(value: Record<string, string>): any {
    if (value['inputs']) {
      return JSON.parse(value['inputs']);
    }
  }

  public parseOutputs(value: Record<string, string>): any {
    if (value['outputs']) {
      return JSON.parse(value['outputs']);
    }
  }

  public formatColumnSchema(spec: ColumnSpec | TensorSpec): string {
    return spec.type === 'tensor' ? this.getTensorTypeRepr(spec) : this.getColumnTypeRepr(spec, 0);
  }

  public getTensorTypeRepr(tensorType: TensorSpec): string {
    return `Tensor (dtype: ${tensorType['tensor-spec'].dtype}, shape: [${tensorType['tensor-spec'].shape}])`;
  }

  public getColumnTypeRepr(columnType: ColumnType, indentationLevel: number): string {
    const { type } = columnType;

    const indentation = ' '.repeat(indentationLevel * INDENTATION_SPACES);
    if (type === 'object') {
      const propertyReprs = Object.keys((columnType as ObjectType).properties).map((propertyName) => {
        const property = (columnType as ObjectType).properties[propertyName];
        const requiredRepr = property.required ? '' : ' (optional)';
        const propertyRepr = this.getColumnTypeRepr(property, indentationLevel + 1);
        const indentOffset = (indentationLevel + 1) * INDENTATION_SPACES;

        return `${' '.repeat(indentOffset)}${propertyName}: ${propertyRepr.slice(indentOffset) + requiredRepr}`;
      });

      return `${indentation}{\n${propertyReprs.join(',\n')}\n${indentation}}`;
    }

    if (type === 'array') {
      const indentOffset = indentationLevel * INDENTATION_SPACES;
      const itemsTypeRepr = this.getColumnTypeRepr((columnType as ArrayType).items, indentationLevel).slice(
        indentOffset,
      );
      return `${indentation}Array(${itemsTypeRepr})`;
    }

    return `${indentation}${type}`;
  }

  protected readonly JSON = JSON;
}
