import { Injectable } from '@angular/core';
import { map, Observable } from 'rxjs';
import { ModelsRegistryListStore } from '../store';
import { ModelsRegistryList, ModelsRegistryListItem } from '@selfai-platform/pipeline-api';

@Injectable({
  providedIn: 'root',
})
export class ModelsRegistryListDomainService {
  constructor(private readonly modelsRegistryListStore: ModelsRegistryListStore) {}

  public getModelsList(): Observable<ModelsRegistryListItem[]> {
    return this.modelsRegistryListStore.entities$ as Observable<ModelsRegistryListItem[]>;
  }

  public loadModelsList(): Observable<ModelsRegistryList> {
    return this.modelsRegistryListStore.getAll().pipe(map((models) => ({ models })));
  }

  public getModelsListLoading(): Observable<boolean> {
    return this.modelsRegistryListStore.loading$ as Observable<boolean>;
  }

  public getModelsListError(): Observable<unknown> {
    return this.modelsRegistryListStore.errors$ as Observable<unknown>;
  }

  public getModelsListLoaded(): Observable<boolean> {
    return this.modelsRegistryListStore.loaded$ as Observable<boolean>;
  }

  public deleteModel(id: string): void {
    this.modelsRegistryListStore.delete(id);
  }
}
