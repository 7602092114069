import { Injectable } from '@angular/core';
import { CubeEditResult, CubeError, CubeState, CubeWorkflowData } from '@selfai-platform/pipeline-common';
import { DialogService } from '@selfai-platform/shell';
import { map, Observable, startWith } from 'rxjs';

@Injectable()
export class CubeDialogStateService {
  constructor(private readonly dialogService: DialogService<CubeEditResult, CubeWorkflowData>) {}

  getCubeState(): Observable<CubeState | undefined | null> {
    const data = this.dialogService.data;

    return new Observable<CubeState | undefined | null>((subscriber) => {
      const stateUpdateHander = (state: CubeState) => {
        subscriber.next(state);
      };

      if (data?.selectedNode?.hasOwnProperty('onStateUpdated')) {
        data?.selectedNode?.onStateUpdated(stateUpdateHander);
      }

      return () => {
        if (data?.selectedNode?.hasOwnProperty('destroyStateUpdated')) {
          data?.selectedNode?.destroyStateUpdated(stateUpdateHander);
        }
      };
    }).pipe(startWith(data?.selectedNode.state));
  }

  getCubeError(): Observable<CubeError | undefined> {
    return this.getCubeState().pipe(map((state) => state?.error));
  }
}
