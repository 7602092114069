import { ModuleWithProviders, NgModule } from '@angular/core';
import { SessionManagerApiService, SessionsManagerAdapter } from '@selfai-platform/pipeline-api';

@NgModule()
export class WorkflowModule {
  static forRoot(): ModuleWithProviders<WorkflowModule> {
    return {
      ngModule: WorkflowModule,
      providers: [{ provide: SessionsManagerAdapter, useExisting: SessionManagerApiService }],
    };
  }
}
