import { HttpClient, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { PipelineConfig, PipelineConfigService } from '@selfai-platform/shared';
import { Observable } from 'rxjs';
import { DataSourcesLibraryFilesInterface } from '../models';

@Injectable({
  providedIn: 'root',
})
export class DataSourceLibraryApiService {
  private readonly apiUrl: string;
  private readonly config: PipelineConfig;

  constructor(
    private http: HttpClient,
    private pipelineConfigService: PipelineConfigService,
  ) {
    this.config = this.pipelineConfigService.getConfig();
    this.apiUrl = `${this.config.hosts.api}/library/`;
  }

  getAll(): Observable<DataSourcesLibraryFilesInterface> {
    return this.http.get<DataSourcesLibraryFilesInterface>(this.apiUrl);
  }

  getFile(filePath: string): Observable<HttpResponse<Blob>> {
    return this.http.get(this.getResourceUrl(filePath), {
      observe: 'response',
      responseType: 'blob',
    });
  }

  addDirectory(directoryName: string, parentDirectoryPath: string): Observable<void> {
    const separator = parentDirectoryPath.endsWith('/') ? '' : '/';
    const directoryPath = `${parentDirectoryPath}${separator}${directoryName}`;

    return this.http.post<void>(this.getResourceUrl(directoryPath), undefined);
  }

  uploadFile(file: File, directoryPath: string): Observable<unknown> {
    const formData = new FormData();
    formData.append('file', file);

    return this.http.post(this.getResourceUrl(directoryPath), formData, {
      headers: { 'Content-Type': undefined },
    });
  }

  deleteDirectory(directoryPath: string): Observable<void> {
    return this.http.delete<void>(this.getResourceUrl(directoryPath));
  }

  deleteFile(filePath: string): Observable<void> {
    return this.http.delete<void>(this.getResourceUrl(filePath));
  }

  private getResourceUrl(resourcePath: string): string {
    return `${this.apiUrl}${this.cleanLeadSlash(resourcePath)}`;
  }

  private cleanLeadSlash(resourcePath: string): string {
    return resourcePath.startsWith('/') ? resourcePath.slice(1) : resourcePath;
  }
}
