import { Injectable } from '@angular/core';
import { EntityCollectionServiceBase, EntityCollectionServiceElementsFactory, EntityDataService } from '@ngrx/data';
import { KE_MODELS_REGISTRY_VERSIONS_ENTITY_NAME } from '@selfai-platform/store';
import { ModelsRegistryVersionsListItem } from '@selfai-platform/pipeline-api';
import { ModelsRegistryVersionsListDataService } from './models-registry-versions-list-data.service';

@Injectable({ providedIn: 'root' })
export class ModelsRegistryVersionsListStore extends EntityCollectionServiceBase<ModelsRegistryVersionsListItem> {
  constructor(
    public entityDataService: EntityDataService,
    public modelsRegistryVersionsDataService: ModelsRegistryVersionsListDataService,
    public serviceElementsFactory: EntityCollectionServiceElementsFactory,
  ) {
    entityDataService.registerService(KE_MODELS_REGISTRY_VERSIONS_ENTITY_NAME, modelsRegistryVersionsDataService);
    super(KE_MODELS_REGISTRY_VERSIONS_ENTITY_NAME, serviceElementsFactory);
  }

  [name: string]: unknown;
}
