<p-panel header="{{ 'models-registry-version.panel.header' | translate }}">
  <div class="mb-4 flex w-full" *ngIf="currentVersion">
    <div class="mr-4">{{ 'models-registry.model-version.field.created-time.label' | translate }}: {{ currentVersion.creation_timestamp | date: 'dd.MM.yyyy HH:mm'  }}</div>
    <div class="mr-4">{{ 'models-registry.model-version.field.stage.label' | translate }}: {{ currentVersion.current_stage }}</div>
    <div class="mr-4">{{ 'models-registry.model-version.field.last-modified.label' | translate }}: {{ currentVersion.last_updated_timestamp | date: 'dd.MM.yyyy HH:mm'  }}</div>
    <div *ngIf="runs$ | async">{{ 'models-registry.model-version.field.source-run.label' | translate }}: {{ (runs$ | async)?.run?.info?.run_name }}</div>
  </div>

  <div *ngIf="modelVersionForm" class="model-version-form mb-3" [formGroup]="modelVersionForm">
    <div class="grid">
      <div class="col">
        <div class="mb-2 flex align-items-center">
          <label for="name" class="mr-2 block w-2">{{ 'models-registry.form.name' | translate }}</label>
          <input id="name" class="w-8" pInputText formControlName="version">
        </div>
      </div>

      <div class="col">
        <div class="mb-2 flex align-items-center">
          <label for="description" class="mr-2 block w-2">{{ 'models-registry.form.description' | translate }}</label>
          <textarea
            id="description"
            class="w-10"
            pInputTextarea
            formControlName="description"
            [rows]="5"
            [autoResize]="true"
          ></textarea>
        </div>

        <div *ngIf="editMode" class="mb-3 flex align-items-center">
          <label for="model-tags" class="mr-3 block w-2">{{ 'models-registry.form.tags' | translate }}</label>
          <p-chips class="w-10" id="model-tags" formControlName="tags" (onRemove)="deleteTag($event)">
            <ng-template let-item pTemplate="item">
              {{ item.key }} : {{ item.value }}
            </ng-template>
          </p-chips>
          <p-button
            class="ml-2"
            icon="pi pi-plus"
            severity="primary"
            [outlined]="true"
            (click)="openModalTagAddDialog()"
          ></p-button>
        </div>
      </div>
    </div>

    <div class="flex">
      <p-button
        class="mr-2"
        label="{{'models-registry.form.actions.back' | translate }}"
        severity="secondary"
        [outlined]="true"
        (click)="onBackEvent()"
      ></p-button>

      <p-button
        label="{{'models-registry.form.actions.save' | translate }}"
        [disabled]="modelVersionForm.invalid || !canUpdate"
        (click)="onSubmitEvent()"
      ></p-button>
    </div>
  </div>

  <selfai-platform-model-schema [signature]="signature$ | async"></selfai-platform-model-schema>
</p-panel>
