import { ChangeDetectionStrategy, Component, Injector, OnInit } from '@angular/core';
import { CubeError } from '@selfai-platform/pipeline-common';
import { DialogHeaderService } from '../../dialog-header/dialog-header.service';
import { CubeDialogManagementService } from '../../../services/cube-dialog-management.service';
import { DialogHelperService } from '../../../../dialog';
import { AbstractCubeDialogFormComponent } from '../../abstract-cube-dialog-form.component';
import { SaveConfirmationService } from '../../../services';
import { NgIf } from '@angular/common';
import { MessageModule } from 'primeng/message';
import { PanelModule } from 'primeng/panel';

@Component({
  selector: 'selfai-platform-error-dialog',
  templateUrl: './error-dialog.component.html',
  styleUrls: ['./error-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [NgIf, MessageModule, PanelModule],
  providers: [DialogHeaderService, DialogHelperService, SaveConfirmationService],
})
export class ErrorDialogComponent extends AbstractCubeDialogFormComponent implements OnInit {
  get error(): CubeError {
    return (this.dialogService.data as any)?.['error'] as CubeError;
  }

  constructor(
    private readonly dialogHeaderService: DialogHeaderService,
    private readonly cubeDialogManagementService: CubeDialogManagementService,
    injector: Injector,
  ) {
    super(injector);
  }

  public ngOnInit(): void {
    this.dialogHeaderService.initCustomHeaderComponent(null, 'Error', this.closeDialog.bind(this));
    setTimeout(() => {
      this.cubeDialogManagementService.setFocus('Error');
    });
  }

  initialItem: object;

  get dataForWorkflow(): object | undefined {
    return undefined;
  }
}
