import { ChangeDetectionStrategy, Component, forwardRef, Input } from '@angular/core';
import { CubeError, CubeState } from '@selfai-platform/pipeline-common';
import { DestroyService } from '@selfai-platform/shared';
import { DialogService } from '@selfai-platform/shell';
import { takeUntil } from 'rxjs';
import { ErrorDialogComponent } from '../../dialogs/error-dialog/error-dialog.component';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { ButtonDirective } from 'primeng/button';
import { DatePipe, NgSwitch, NgSwitchCase } from '@angular/common';

@Component({
  selector: 'selfai-platform-error-button',
  templateUrl: './error-button.component.html',
  styleUrls: ['./error-button.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [DestroyService],
  imports: [
    forwardRef(() => ErrorDialogComponent),
    OverlayPanelModule,
    ButtonDirective,
    NgSwitch,
    NgSwitchCase,
    DatePipe,
  ],
  standalone: true,
})
export class ErrorButtonComponent {
  @Input() state!: CubeState;

  constructor(
    private readonly destroy$: DestroyService,
    private readonly dialogService: DialogService<undefined, { error: CubeError; selectedNode: { id: 'error' } }>,
  ) {}

  openErrorDialog(): void {
    this.dialogService
      .showDialog(ErrorDialogComponent, {
        header: 'Error',
        width: '75%',
        data: { error: this.state.error, selectedNode: { id: 'error' } },
        modal: true,
        showHeader: true,
        height: '90vh',
        maximizable: true,
        closeOnEscape: false,
      })
      .pipe(takeUntil(this.destroy$))
      .subscribe();
  }
}
