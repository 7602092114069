import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { DestroyService } from '@selfai-platform/shared';
import {
  DataListViewComponent,
  provideDataListView,
  provideDialogService,
} from '@selfai-platform/shell';
import {
  ModelsRegistryVersionsActionsForItemsService,
  ModelsRegistryVersionsDataListViewService,
} from '../../services';
import { AsyncPipe } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { ModelsRegistryVersionsListStore } from '../../store';

@Component({
  selector: 'selfai-platform-models-registry-versions-list',
  templateUrl: './models-registry-versions-list.component.html',
  styleUrls: ['./models-registry-versions-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    AsyncPipe,
    DataListViewComponent,
    TranslateModule,
  ],
  providers: [
    DestroyService,
    ...provideDialogService(),
    ...provideDataListView(
      ModelsRegistryVersionsDataListViewService,
      ModelsRegistryVersionsActionsForItemsService
    ),
  ],
})
export class ModelsRegistryVersionsListComponent implements OnInit {
  public columns = this.modelsRegistryVersionsDataListViewService.getColumns();
  public groupActions$ = this.modelsRegistryVersionsDataListViewService.getGroupActions();

  constructor(
    private readonly modelsRegistryVersionsDataListViewService: ModelsRegistryVersionsDataListViewService,
    private readonly modelVersionsStore: ModelsRegistryVersionsListStore,
  ) {}

  public ngOnInit(): void {
    this.modelVersionsStore.clearCache();
  }
}
