import { Injectable } from '@angular/core';
import { AlertService, PipelineConfigService } from '@selfai-platform/shared';
import { filter, map, Observable, tap } from 'rxjs';
import { Update } from '@ngrx/entity/src/models';
import {
  IModel,
  IModelsRegistryModelTag,
  IModelsRegistryModelVersion,
  IModelsRegistryRun,
  ModelsRegistryListItem,
  ModelsRegistryVersionsListItem,
} from '../models';
import { omit } from 'lodash';
import { parse } from 'yaml';
import { HttpClient } from '@angular/common/http';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root',
})
export class ModelsRegistryApiService {
  private readonly modelsRegistryPath = 'models';
  private readonly modelsRegistryPathUri: string;
  private readonly modelsPath = 'ajax-api/2.0/mlflow';
  private readonly registeredModelsPath = 'registered-models';
  private readonly modelVersionsPath = 'model-versions';
  private readonly runsPath = 'runs';
  private readonly artifactsModelPath = 'MLmodel';

  private readonly apiUrl: string;
  private readonly registeredModelsUrl: string;
  private readonly runsApiUrl: string;
  private readonly modelVersionsUrl: string;

  constructor(
    private readonly http: HttpClient,
    private readonly pipelineConfigService: PipelineConfigService,
    private readonly alertService: AlertService,
    private readonly translateService: TranslateService,
  ) {
    const config = this.pipelineConfigService.getConfig();
    this.modelsRegistryPathUri = config.hosts.modelsRegistry;
    this.apiUrl = `${this.modelsRegistryPathUri}/${this.modelsPath}`;
    this.registeredModelsUrl = `${this.apiUrl}/${this.registeredModelsPath}`;
    this.runsApiUrl = `${this.apiUrl}/${this.runsPath}`;
    this.modelVersionsUrl = `${this.apiUrl}/${this.modelVersionsPath}`;
  }

  public loadList(): Observable<IModel[]> {
    const params = new URLSearchParams();
    params.set('filter', '');
    params.set('max_result', '10');
    params.set('order_by', 'name+ASC');

    return this.http
      .get<{
        registered_models: IModel[];
      }>(this.registeredModelsUrl + '/search?' + params.toString().replace('%2B', '+'))
      .pipe(map((res: { registered_models: IModel[] }) => res.registered_models));
  }

  public getModel(name: string): Observable<IModel> {
    return this.http
      .get<{ registered_model: IModel }>(this.registeredModelsUrl + '/get?name=' + name)
      .pipe(map(({ registered_model }) => registered_model));
  }

  public addModel(model: ModelsRegistryListItem): Observable<IModel> {
    return this.http
      .post<{
        registered_model: IModel;
      }>(this.registeredModelsUrl + '/create', { name: model.name, description: model.description })
      .pipe(
        map(({ registered_model }) => ({ ...registered_model, id: registered_model.name })),
        tap({
          next: () => this.alertSuccess('models-registry.notifications.model.add.success'),
          error: () => this.alertError('models-registry.notifications.model.add.error'),
        }),
      );
  }

  public updateModel(updateModel: Update<ModelsRegistryListItem>): Observable<IModel> {
    return this.http
      .patch<IModel>(this.registeredModelsUrl + '/update', {
        ...omit(updateModel.changes, 'id'),
        name: updateModel.changes.id,
      })
      .pipe(
        tap({
          next: () => this.alertSuccess('models-registry.notifications.model.update.success'),
          error: () => this.alertError('models-registry.notifications.model.update.error'),
        }),
      );
  }

  public deleteModel(name: string): Observable<string> {
    return this.http.delete<string>(this.registeredModelsUrl + '/delete', { body: { name } }).pipe(
      tap({
        next: () => this.alertSuccess('models-registry.notifications.model.delete.success'),
        error: () => this.alertError('models-registry.notifications.model.delete.error'),
      }),
    );
  }

  public addTag(model: IModel, tag: IModelsRegistryModelTag, version?: string): Observable<IModelsRegistryModelTag> {
    const body: { name: string; key: string; value: string; version?: string } = {
      name: model.name,
      ...tag,
    };

    if (version) {
      body.version = version;
    }

    return this.http.post<IModelsRegistryModelTag>(this.registeredModelsUrl + '/set-tag', { ...body, version });
  }

  public deleteTag(
    modelName: string,
    tag: IModelsRegistryModelTag,
    version?: string,
  ): Observable<IModelsRegistryModelTag> {
    return this.http.delete<IModelsRegistryModelTag>(this.registeredModelsUrl + '/delete-tag', {
      body: { key: tag.key, name: modelName, version },
    });
  }

  public getModelVersion(name: string, version: string): Observable<IModelsRegistryModelVersion> {
    return this.http
      .get<{
        model_version: IModelsRegistryModelVersion;
      }>(`${this.modelVersionsUrl}/get?name=${name}&version=${version}`)
      .pipe(map(({ model_version }) => model_version));
  }

  public getModelVersions(modelName: string): Observable<IModelsRegistryModelVersion[]> {
    return this.http
      .get<{
        model_versions: IModelsRegistryModelVersion[];
      }>(`${this.modelVersionsUrl}/search?filter=name%3D'${modelName}'`)
      .pipe(
        filter(Boolean),
        map(({ model_versions }) => model_versions),
      );
  }

  public addModelVersion(model: ModelsRegistryVersionsListItem): Observable<IModelsRegistryModelVersion> {
    return this.http
      .put<IModelsRegistryModelVersion>(`${this.registeredModelsUrl}/${this.modelsRegistryPath}/${model.id}`, model)
      .pipe(
        tap({
          next: () => this.alertSuccess('models-registry.notifications.model-version.add.success'),
          error: () => this.alertError('models-registry.notifications.model-version.add.error'),
        }),
      );
  }

  public updateModelVersion(updateModel: Update<ModelsRegistryListItem>): Observable<IModelsRegistryModelVersion> {
    return this.http
      .put<IModelsRegistryModelVersion>(
        `${this.registeredModelsUrl}/${this.modelsRegistryPath}/${updateModel.changes.id}`,
        updateModel.changes,
      )
      .pipe(
        tap({
          next: () => this.alertSuccess('models-registry.notifications.model-version.update.success'),
          error: () => this.alertError('models-registry.notifications.model-version.update.error'),
        }),
      );
  }

  public deleteModelVersion(id: string): Observable<string> {
    return this.http.delete<string>(`${this.registeredModelsUrl}/${this.modelsRegistryPath}/${id}`).pipe(
      tap({
        next: () => this.alertSuccess('models-registry.notifications.model-version.delete.success'),
        error: () => this.alertError('models-registry.notifications.model-version.delete.error'),
      }),
    );
  }

  public getRuns(runId: string): Observable<IModelsRegistryRun> {
    return this.http.get<IModelsRegistryRun>(`${this.runsApiUrl}/get?run_id=${runId}`);
  }

  public getArtifacts(modelName: string, modelVersion: string): Observable<any> {
    return this.http
      .get(
        `${this.modelsRegistryPathUri}/${this.modelVersionsPath}/get-artifact?path=${this.artifactsModelPath}&name=${modelName}&version=${modelVersion}`,
        { responseType: 'text' },
      )
      .pipe(map((data: string) => parse(data, { schema: 'failsafe' })));
  }

  private alertSuccess(message: string): void {
    this.alertService.success(this.translateService.instant(message));
  }

  private alertError(message: string): void {
    this.alertService.error(this.translateService.instant(message));
  }
}
