import { ChangeDetectionStrategy, Component } from '@angular/core';
import { DialogService } from '@selfai-platform/shell';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { Button } from 'primeng/button';
import { FloatLabelModule } from 'primeng/floatlabel';
import { InputTextModule } from 'primeng/inputtext';
import { FormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { IModelsRegistryModelTag } from '@selfai-platform/pipeline-api';
import { TranslateModule } from '@ngx-translate/core';

export interface IModelTag {
  name: string;
  value: string;
}

@Component({
  selector: 'selfai-platform-model-tag-add-dialog',
  templateUrl: './model-tag-add-dialog.component.html',
  styleUrls: ['./model-tag-add-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  providers: [DialogService],
  imports: [Button, CommonModule, FloatLabelModule, InputTextModule, FormsModule, TranslateModule],
})
export class ModelTagAddDialogComponent {
  public newTagKey: string;
  public newTagValue: string;

  constructor(
    private readonly dialogConfig: DynamicDialogConfig,
    private readonly ref: DynamicDialogRef,
  ) {}

  public addTagToModel(): void {
    this.ref.close({
      key: this.newTagKey,
      value: this.newTagValue,
    } as IModelsRegistryModelTag);
  }

  public cancel(): void {
    this.ref.close();
  }
}
