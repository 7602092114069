import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AggregatorDictionariesApiModel } from '../models';
import { AggrtConfigService, AggregatorCoreApiService } from '@selfai-platform/pipeline-shared';

@Injectable({
  providedIn: 'root',
})
export class AggregatorDictionariesApiService extends AggregatorCoreApiService {
  loadDictionaries(): Observable<AggregatorDictionariesApiModel> {
    const url = this.buildUrl('/routes/getDictionaries');

    return this.http.get<AggregatorDictionariesApiModel>(url);
  }

  constructor(
    private readonly http: HttpClient,
    aggrtConfigService: AggrtConfigService,
  ) {
    super(aggrtConfigService);
  }
}
