import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { ModelsRegistryListComponent } from '../../components';
import { map, Observable, takeUntil } from 'rxjs';
import {
  BreadcrumbsMenuItem,
  BreadcrumbsMenuService,
  KE_MODELS_REGISTRY_PATH,
  KE_ROOT_ROUTE,
} from '@selfai-platform/shell';
import { TranslateService } from '@ngx-translate/core';
import { DestroyService } from '@selfai-platform/shared';
import { ModelsRegistryListStore } from '../../store';
import { ModelsRegistryApiService } from '@selfai-platform/pipeline-api';

@Component({
  selector: 'selfai-platform-models-registry-list-container',
  templateUrl: './models-registry-list-container.component.html',
  styleUrls: ['./models-registry-list-container.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [ModelsRegistryListComponent],
})
export class ModelsRegistryListContainerComponent implements OnInit {
  public breadcrumbItems$: Observable<BreadcrumbsMenuItem[]> = this.translateService
    .stream('shell.menu.models-registry')
    .pipe(
      map((label) => [
        {
          label,
          routerLink: ['/', KE_ROOT_ROUTE, KE_MODELS_REGISTRY_PATH],
        },
      ]),
    );

  constructor(
    private readonly translateService: TranslateService,
    private readonly destroy$: DestroyService,
    private readonly breadcrumbsMenuService: BreadcrumbsMenuService,
    private readonly modelsRegistryListStore: ModelsRegistryListStore,
    private readonly modelsRegistryApiService: ModelsRegistryApiService,
  ) {}

  public ngOnInit(): void {
    this.breadcrumbItems$.pipe(takeUntil(this.destroy$)).subscribe((breadcrumbItems) => {
      this.breadcrumbsMenuService.setBreadcrumbsMenu(breadcrumbItems);
    });
    this.modelsRegistryListStore.getAll();
  }
}
