<selfai-platform-data-list-view
  [layoutTypes]="['table']"
  [columns]="columns"
  [actions]="actions$ | async"
  [showEntityIcon]="true"
  [groupActions]="groupActions$ | async"
  [emptyMessage]="'shell.board.filter.ui.search-all.nodata' | translate"
  [globalFilterFields]="filtersFields"
>
  <ng-template selfaiPlatformDataListViewTemplateName="fieldValue.tags" let-item>
    <div class="tags">
      <div class="overflow-x-scroll tags-container">
        <p-tag class="mr-2" *ngFor="let tag of item.tags">
          <div class="flex align-items-center gap-2 px-1">
            <span class="text-base font-normal">{{ tag.key + ' : ' + tag.value }}</span>
          </div>
        </p-tag>
      </div>
    </div>
  </ng-template>
</selfai-platform-data-list-view>
