import { Injectable } from '@angular/core';
import {
  PermissionService,
  PipelinePermission,
  PipelinePermissionEntity,
  SchedulerPermissionAction,
} from '@selfai-platform/shared';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class SchedulesPermissionService {
  constructor(protected readonly permissionService: PermissionService) {}

  public checkPermission(permissionAction: SchedulerPermissionAction, isOwner?: boolean): Observable<boolean> {
    return this.permissionService.checkPermission({
      entity: PipelinePermissionEntity.Schedules,
      isOwner,
      action: permissionAction,
    });
  }

  public hasPermission(permission: PipelinePermission): boolean {
    return this.permissionService.getPipelinePermissions().includes(permission);
  }
}
