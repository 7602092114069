import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { BiPermissionService, WorkspaceListItem } from '@selfai-platform/bi-domain';
import { BiPermission } from '@selfai-platform/shared';
import { ActionsForItemService, DataListItemAction, KNOWLEDGE_DESIGNER_ROOT_ROUTE } from '@selfai-platform/shell';
import { Observable, of } from 'rxjs';
import { WorkspaceDataListViewService } from './workspace-data-list-view.service';

@Injectable()
export class WorkspaceActionsForItemService extends ActionsForItemService<WorkspaceListItem> {
  constructor(
    private readonly workspaceDataListViewService: WorkspaceDataListViewService,
    private readonly permissionService: BiPermissionService,
    private readonly router: Router,
  ) {
    super();
  }

  override getActionsForItem(workspace: WorkspaceListItem): Observable<DataListItemAction[]> {
    const menuItems: DataListItemAction[] = [];
    // TODO: add actions for workspace list
    if (this.hasPermission(BiPermission.KD_WORKSPACE_EDIT_ALL)) {
      menuItems.push({
        labelTranslate: 'msg.workspace-list.table-actions.permissions',
        icon: 'pi pi-user-edit',
        action: () =>
          this.router.navigate(['/', KNOWLEDGE_DESIGNER_ROOT_ROUTE, 'workspace', workspace.id, 'manage-roles']),
      });
    }

    if (this.hasPermission(BiPermission.KD_WORKSPACE_DELETE_ALL)) {
      menuItems.push({
        labelTranslate: 'msg.workspace-list.table-actions.remove',
        icon: 'pi pi-trash',
        action: (eventTarget) => this.workspaceDataListViewService.remove(workspace, eventTarget),
      });
    }

    return of(menuItems);
  }

  hasPermission(permission: BiPermission): boolean {
    return this.permissionService.hasPermission(permission);
  }
}
