import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { WorkflowClonePayload, WorkflowInfo } from '@selfai-platform/pipeline-common';
import { AlertService } from '@selfai-platform/shared';
import { map, Observable, ReplaySubject, withLatestFrom } from 'rxjs';
import { WorkflowApiService } from '@selfai-platform/pipeline-api';
import { KE_ROOT_ROUTE, KE_WORKFLOW_PATH } from '@selfai-platform/shell';

@Injectable({
  providedIn: 'root',
})
export class WorkflowListService extends ReplaySubject<WorkflowInfo[]> {
  constructor(
    private readonly workflowApiService: WorkflowApiService,
    private readonly alertService: AlertService,
    private readonly router: Router,
  ) {
    super(1);
  }

  loadWorkflowList(): void {
    this.workflowApiService.getAllWorkflows().subscribe({
      next: this.next.bind(this),
      error: () => {
        this.alertService.error('Error of loading workflow');
      },
    });
  }

  getWorkflowList(): Observable<WorkflowInfo[]> {
    return this.asObservable();
  }

  getWorkflowById(id: string): Observable<WorkflowInfo | undefined> {
    return this.getWorkflowList().pipe(map((workflowList) => workflowList.find((workflow) => workflow.id === id)));
  }

  deleteWorkflow(id: string): void {
    this.workflowApiService
      .deleteWorkflow(id)
      .pipe(withLatestFrom(this.getWorkflowList()))
      .subscribe({
        next: ([, workflowList]) => {
          this.next(workflowList.filter((workflow) => workflow.id !== id));
        },
        error: () => {
          this.alertService.error('Error of deleting workflow');
        },
      });
  }

  cloneWorkflow(payload: WorkflowClonePayload): void {
    this.workflowApiService.cloneWorkflow(payload).subscribe({
      next: () => {
        this.loadWorkflowList();
      },
      error: () => {
        this.alertService.error('Error of cloning workflow');
      },
    });
  }

  addWorkflow(payload: WorkflowClonePayload): void {
    this.workflowApiService.createWorkflow(payload).subscribe({
      next: ({ workflowId }) => {
        this.router.navigate([KE_ROOT_ROUTE, KE_WORKFLOW_PATH, workflowId]);
      },
      error: () => {
        this.alertService.error('Error of adding workflow');
      },
    });
  }

  getWorkflowFileUploadUrl(): string {
    return this.workflowApiService.getUploadWorkflowMethodUrl();
  }
}
