import { Injectable } from '@angular/core';
import { AggregatorRouteApiModel } from '@selfai-platform/aggregator-api';
import { AggregatorRouteListService } from './aggregator-camel-list.service';
import { AggregatorItemService } from './aggregator-item.service';

@Injectable()
export class AggregatorImportService {
  constructor(
    private readonly aggregatorRouteListService: AggregatorRouteListService,
    private readonly aggregatorItemService: AggregatorItemService,
  ) {}

  importRoute(routeData: AggregatorRouteApiModel): void {
    this.aggregatorRouteListService.addRoute(routeData);

    this.aggregatorRouteListService.saveRoutes().subscribe();
  }

  replaceRoute(uuid: string, route: AggregatorRouteApiModel, withSave = false): void {
    this.aggregatorItemService.updateRoute(uuid, route);

    if (withSave) {
      this.aggregatorRouteListService.saveRoutes().subscribe();
    }
  }

  replaceAllRoutes(routes: AggregatorRouteApiModel[]): void {
    this.aggregatorRouteListService.clearRoutes();
    routes.forEach((route) => this.aggregatorRouteListService.initRoute(route));

    this.aggregatorRouteListService.saveRoutes().subscribe();
  }
}
