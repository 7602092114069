import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { FormFieldComponentModule } from '@selfai-platform/pipeline-shared';
import { CheckboxModule } from 'primeng/checkbox';
import { DropdownModule } from 'primeng/dropdown';
import { MessageModule } from 'primeng/message';
import { DataSourceSelectorComponent } from '../../data-source-selector';
import { DialogHeaderService } from '../../dialog-header/dialog-header.service';
import { WriteDataFrameComponent } from '../write-data-frame';

@Component({
  selector: 'selfai-platform-write-data-frame-transit',
  templateUrl: './write-data-frame-transit.component.html',
  styleUrl: './write-data-frame-transit.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    CommonModule,
    FormFieldComponentModule,
    DataSourceSelectorComponent,
    CheckboxModule,
    ReactiveFormsModule,
    TranslateModule,
    MessageModule,
    DropdownModule,
  ],
  providers: [DialogHeaderService],
})
export class WriteDataFrameTransitComponent extends WriteDataFrameComponent {}
