import { EntityMetadataMap } from '@ngrx/data';

export const KE_MODELS_REGISTRY_VERSIONS_ENTITY_NAME = 'keModelsRegistryVersions';

const entityMetadata: EntityMetadataMap = {
  [KE_MODELS_REGISTRY_VERSIONS_ENTITY_NAME]: {
    entityDispatcherOptions: { optimisticAdd: false, optimisticUpdate: false },
  },
};

export const keModelsRegistryVersionsEntityConfig = {
  entityMetadata,
};
