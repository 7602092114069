import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { CodeEditorComponentModule, FormFieldComponentModule } from '@selfai-platform/pipeline-shared';
import { CardModule } from 'primeng/card';
import { DropdownModule } from 'primeng/dropdown';
import { InputNumberModule } from 'primeng/inputnumber';
import { InputTextModule } from 'primeng/inputtext';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { EditSelectionFieldComponentModule } from '../../../../../edit-selection-field/edit-selection-field.module';
import { ExpressionEditComponent } from './expression-edit.component';

@NgModule({
  imports: [
    CommonModule,
    InputTextModule,
    InputNumberModule,
    ReactiveFormsModule,
    DropdownModule,
    FormFieldComponentModule,
    CardModule,
    EditSelectionFieldComponentModule,
    InputTextareaModule,
    CodeEditorComponentModule,
  ],
  declarations: [ExpressionEditComponent],
  exports: [ExpressionEditComponent],
})
export class ExpressionEditComponentModule {}
