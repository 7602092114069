import { Injectable } from '@angular/core';
import { EntityCollectionServiceBase, EntityCollectionServiceElementsFactory, EntityDataService } from '@ngrx/data';
import { KE_MODELS_REGISTRY_ENTITY_NAME } from '@selfai-platform/store';
import { ModelsRegistryListDataService } from './models-registry-list-data.service';
import { ModelsRegistryListItem } from '@selfai-platform/pipeline-api';

@Injectable({ providedIn: 'root' })
export class ModelsRegistryListStore extends EntityCollectionServiceBase<ModelsRegistryListItem> {
  constructor(
    public entityDataService: EntityDataService,
    public modelsRegistryDataService: ModelsRegistryListDataService,
    public serviceElementsFactory: EntityCollectionServiceElementsFactory,
  ) {
    entityDataService.registerService(KE_MODELS_REGISTRY_ENTITY_NAME, modelsRegistryDataService);
    super(KE_MODELS_REGISTRY_ENTITY_NAME, serviceElementsFactory);
  }

  [name: string]: unknown;
}
