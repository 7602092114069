import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { Button } from 'primeng/button';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { AutoCompleteModule } from 'primeng/autocomplete';
import { FormBuilder, FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { PanelModule } from 'primeng/panel';
import { InputTextModule } from 'primeng/inputtext';
import {
  BreadcrumbsMenuItem,
  BreadcrumbsMenuService,
  DataListViewComponent,
  KE_MODELS_REGISTRY_PATH,
  KE_ROOT_ROUTE,
  provideDataListView,
  provideDialogService,
} from '@selfai-platform/shell';
import { ActivatedRoute, Router } from '@angular/router';
import { combineLatest, switchMap, takeUntil } from 'rxjs';
import { IModelsRegistryModelTag, ModelsRegistryListItem } from '@selfai-platform/pipeline-api';
import {
  ModelsRegistryActionsForItemsService,
  ModelsRegistryDataListViewService,
  ModelsRegistryPermissionService,
} from '../../services';
import { ModelsRegistryApiService } from '@selfai-platform/pipeline-api';
import { DestroyService, ModelsRegistryPermissionAction } from '@selfai-platform/shared';
import { AsyncPipe, DatePipe, JsonPipe, NgClass, NgIf } from '@angular/common';
import { ModelsRegistryListComponent } from '../models-registry-list';
import { ModelsRegistryVersionsListDataService, ModelsRegistryVersionsListStore } from '../../store';
import { ChipsModule } from 'primeng/chips';
import { DialogService, DynamicDialogRef } from 'primeng/dynamicdialog';
import { ModelTagAddDialogComponent } from '../model-tag-add-dialog';
import { ModelsRegistryVersionsListComponent } from '../models-registry-versions-list';
import { CardModule } from 'primeng/card';

@Component({
  selector: 'selfai-platform-model',
  templateUrl: './model.component.html',
  styleUrls: ['./model.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    Button,
    TranslateModule,
    AutoCompleteModule,
    ReactiveFormsModule,
    InputTextareaModule,
    PanelModule,
    InputTextModule,
    NgIf,
    ChipsModule,
    ModelsRegistryVersionsListComponent,
    CardModule,
    DatePipe,
    NgClass,
  ],
  providers: [
    ...provideDialogService(),
    ...provideDataListView(ModelsRegistryDataListViewService, ModelsRegistryActionsForItemsService),
  ],
})
export class ModelComponent implements OnInit {
  public ref: DynamicDialogRef;
  public id: string = this.activatedRoute?.snapshot?.params['modelId'];
  public editMode = !!this.id;
  public modelForm: FormGroup;
  public breadcrumbItems: BreadcrumbsMenuItem[] = [
    {
      label: this.translate.instant('shell.menu.models-registry'),
      routerLink: ['/', KE_ROOT_ROUTE, KE_MODELS_REGISTRY_PATH],
    },
  ];
  public canUpdate: boolean = true;

  public visibility: boolean = true;

  @Input() models: ModelsRegistryListItem[];
  @Input() currentModel: ModelsRegistryListItem;

  @Output() backEvent: EventEmitter<void> = new EventEmitter<void>();
  @Output() submitEvent: EventEmitter<ModelsRegistryListItem> = new EventEmitter<ModelsRegistryListItem>();

  constructor(
    private readonly fb: FormBuilder,
    private readonly route: ActivatedRoute,
    private readonly changeDetectorRef: ChangeDetectorRef,
    private readonly activatedRoute: ActivatedRoute,
    private readonly translate: TranslateService,
    private readonly breadcrumbsMenuService: BreadcrumbsMenuService,
    private readonly modelsRegistryPermissionService: ModelsRegistryPermissionService,
    private readonly router: Router,
    private readonly modelsRegistryVersionsListStore: ModelsRegistryVersionsListStore,
    private readonly modelsRegistryVersionsListDataService: ModelsRegistryVersionsListDataService,
    private readonly modelsRegistryApiService: ModelsRegistryApiService,
    private readonly dialogService: DialogService,
    private readonly destroy$: DestroyService,
  ) {}

  public createForm(): void {
    this.modelForm = this.fb.group({
      name: new FormControl('', [Validators.required]),
      description: new FormControl('', [Validators.required]),
      tags: new FormControl(''),
    });
  }

  public onBackEvent(): void {
    this.backEvent.emit();
  }

  public onSubmitEvent(): void {
    const preparedData = {
      ...this.modelForm.value,
    };
    if (this.editMode) {
      preparedData.id = this.id;
    }
    this.submitEvent.emit(preparedData);
  }

  public ngOnInit(): void {
    if (!this.editMode) {
      this.breadcrumbItems?.push({
        label: `${this.translate.instant('shell.bc.models-registry.label.create')}`,
        routerLink: ['/', KE_ROOT_ROUTE, KE_MODELS_REGISTRY_PATH, 'create'],
      });
      this.breadcrumbsMenuService.setBreadcrumbsMenu(this.breadcrumbItems);
      this.createForm();
      this.changeDetectorRef.detectChanges();
      this.visibility = false;
    }
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes['models']?.currentValue?.length && this.editMode && !this.currentModel) {
      this.currentModel = this.models.find((model) => {
        return model.name === this.id;
      });
      this.modelsRegistryVersionsListDataService.currentModel.next(this.currentModel);
      this.breadcrumbItems?.push({
        label: `${this.translate.instant('shell.bc.models-registry.label.edit')} ${this.currentModel?.name}`,
        routerLink: ['/', KE_ROOT_ROUTE, KE_MODELS_REGISTRY_PATH, 'edit', this.currentModel?.id],
      });
      this.breadcrumbsMenuService.setBreadcrumbsMenu(this.breadcrumbItems);
      this.createForm();
      this.modelForm.get('name')?.disable();
      combineLatest([
        this.modelsRegistryPermissionService.checkPermission(ModelsRegistryPermissionAction.Update),
        this.modelsRegistryPermissionService.checkPermission(ModelsRegistryPermissionAction.Get),
      ]).subscribe(([canUpdate, canView]) => {
        if (!canView) {
          this.router.navigate(['access-denied']).then();
        }
        this.canUpdate = canUpdate;
        if (!canUpdate) {
          this.modelForm.disable();
        }
      });
      this.modelForm.patchValue(this.currentModel);
      this.visibility = true;
      this.changeDetectorRef.detectChanges();
    }
  }

  public deleteTag(event: IDeleteTagEvent): void {
    this.modelsRegistryApiService
      .deleteTag(this.currentModel.name, event.value)
      .pipe(takeUntil(this.destroy$))
      .subscribe();
  }

  public openModalTagAddDialog(): void {
    this.ref = this.dialogService.open(ModelTagAddDialogComponent, {
      width: '40vw',
      modal: true,
      breakpoints: {
        '960px': '75vw',
        '640px': '90vw',
      },
      data: this.currentModel,
    });

    this.ref.onClose
      .pipe(switchMap((data) => this.modelsRegistryApiService.addTag(this.currentModel, data)))
      .subscribe();
  }
}

export interface IDeleteTagEvent {
  originalEvent: Event;
  value: IModelsRegistryModelTag;
}
