import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  forwardRef,
  HostListener,
  Input,
  Optional,
  Output,
} from '@angular/core';
import { CubeState } from '@selfai-platform/pipeline-common';
import { DialogHelperService } from '../../../dialog';

import { AlertService, DestroyService } from '@selfai-platform/shared';
import { EditorStateService } from '@selfai-platform/storage';
import { Observable } from 'rxjs';
import { CubeDialogStateService } from '../../services/cube-dialog-state.service';
import { ErrorButtonComponent } from './error-button';
import { AsyncPipe, NgClass, NgIf } from '@angular/common';
import { Ripple } from 'primeng/ripple';
import { Button, ButtonDirective } from 'primeng/button';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'selfai-platform-dialog-header',
  templateUrl: './dialog-header.component.html',
  styleUrls: ['./dialog-header.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [DestroyService],
  imports: [
    forwardRef(() => ErrorButtonComponent),
    AsyncPipe,
    NgIf,
    Ripple,
    ButtonDirective,
    NgClass,
    TranslateModule,
    Button,
  ],
  standalone: true,
})
export class DialogHeaderComponent {
  @HostListener('mousedown')
  setFocusEvent() {
    this.setFocus.emit(this.nodeId);
  }

  @HostListener('document:keydown.escape', ['$event'])
  onKeydownHandler(_event: KeyboardEvent) {
    this.closeDialog.emit();
  }

  @Input() header: string;
  @Input() nodeId!: string;
  @Input() isEmpty!: boolean;
  @Input() saveButtonDisabled = false;
  @Input() minimizeButtonShow = true;

  @Output() closeDialog = new EventEmitter<never>();
  @Output() clickSave = new EventEmitter<never>();
  @Output() setFocus = new EventEmitter<string>();
  @Output() minimize = new EventEmitter<string>();

  cubeState$: Observable<CubeState> = this.cubeDialogStateService.getCubeState();

  constructor(
    private readonly cubeDialogStateService: CubeDialogStateService,
    private readonly alertService: AlertService,
    private readonly editorStateService: EditorStateService,
    @Optional() public readonly dialogHelperService?: DialogHelperService,
  ) {}

  maximizeDialog(): void {
    this.dialogHelperService?.maximizeDialog();
  }

  copyIdToClipboard() {
    navigator.clipboard.writeText(this.nodeId);
    this.alertService.success('Node ID copied to clipboard');
  }

  get workflowIsInEditMode(): Observable<boolean> {
    return this.editorStateService.getIsEditMode();
  }
}
