export interface TableColumn<T extends object> {
  labelTranslate: string;
  fieldCode: keyof T;
  fieldType: FieldType;
  sortable?: boolean;
  filterable?: boolean;
  resizable?: boolean;
  width?: string;
  classStyle?: string;
}

export const FieldType = {
  Text: 'text',
  Date: 'date',
  Number: 'number',
  Boolean: 'boolean',
  Icon: 'icon',
  Custom: 'custom',
} as const;

export type FieldType = typeof FieldType[keyof typeof FieldType];
