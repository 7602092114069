import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { PipelineConfig, PipelineConfigService } from '@selfai-platform/shared';
import { map, Observable } from 'rxjs';
import { WorkflowPresets } from '@selfai-platform/pipeline-common';

@Injectable({
  providedIn: 'root',
})
export class WorkflowPresetsApiService {
  config: PipelineConfig;
  apiUrl: string;

  constructor(
    private readonly http: HttpClient,
    private pipelineConfigService: PipelineConfigService,
  ) {
    this.config = this.pipelineConfigService.getConfig();
    this.apiUrl = `${this.config.hosts.api}/${this.config.versions.url}/presets`;
  }

  getAllPresets(): Observable<WorkflowPresets[]> {
    return this.http.get<WorkflowPresets[]>(`${this.apiUrl}`);
  }

  gePresetById(id: number): Observable<WorkflowPresets> {
    return this.http.get<WorkflowPresets>(`${this.apiUrl}/${id}`);
  }

  addPreset(data: WorkflowPresets): Observable<WorkflowPresets> {
    return this.http
      .post(`${this.apiUrl}`, data, {
        headers: {
          Accept: 'text/plain',
        },
        responseType: 'text',
      })
      .pipe(
        map(() => {
          return data;
        }),
      );
  }

  updatePreset(data: Partial<WorkflowPresets>): Observable<WorkflowPresets> {
    return this.http
      .post(
        `${this.apiUrl}/${data.id}`,
        { ...data, id: Number(data.id) },
        {
          headers: {
            Accept: 'text/plain',
          },
          responseType: 'text',
        },
      )
      .pipe(
        map(() => {
          return data as WorkflowPresets;
        }),
      );
  }

  deletePreset(id: string): Observable<string> {
    return this.http
      .delete(`${this.apiUrl}/${id}`, {
        headers: {
          Accept: 'text/plain',
        },
        responseType: 'text',
      })
      .pipe(
        map(() => {
          return id;
        }),
      );
  }
}
