import { Injectable } from '@angular/core';
import { map, Observable } from 'rxjs';
import { ModelsRegistryVersionsList, ModelsRegistryVersionsListItem } from '@selfai-platform/pipeline-api';
import { ModelsRegistryVersionsListStore } from '../store';

@Injectable({
  providedIn: 'root',
})
export class ModelsRegistryVersionsListDomainService {
  constructor(private readonly modelsRegistryVersionsListStore: ModelsRegistryVersionsListStore) {}

  public getModelVersionsList(): Observable<ModelsRegistryVersionsListItem[]> {
    return this.modelsRegistryVersionsListStore.entities$ as Observable<ModelsRegistryVersionsListItem[]>;
  }

  public loadModelVersionsList(): Observable<ModelsRegistryVersionsList> {
    return this.modelsRegistryVersionsListStore.getAll().pipe(map((models) => ({ models })));
  }

  public getModelVersionsListLoading(): Observable<boolean> {
    return this.modelsRegistryVersionsListStore.loading$ as Observable<boolean>;
  }

  public getModelVersionsListError(): Observable<unknown> {
    return this.modelsRegistryVersionsListStore.errors$ as Observable<unknown>;
  }

  public getModelVersionsListLoaded(): Observable<boolean> {
    return this.modelsRegistryVersionsListStore.loaded$ as Observable<boolean>;
  }

  public deleteModelVersion(id: string): void {
    this.modelsRegistryVersionsListStore.delete(id);
  }
}
