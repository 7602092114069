import { Injectable } from '@angular/core';
import { Profile } from '@selfai-platform/pipeline-common';
import { KE_CURRENT_USER } from '@selfai-platform/store';
import { combineLatest, filter, map, Observable, take } from 'rxjs';
import { KeCurrentUserStore } from '../store';

@Injectable({ providedIn: 'root' })
export class KeCurrentUserDomainService {
  constructor(private readonly store: KeCurrentUserStore) {}

  loadProfile(): void {
    this.store.getByKey(KE_CURRENT_USER).pipe(take(1)).subscribe();
  }

  loadProfileIfEmpty(): void {
    combineLatest({
      entities: this.store.entityMap$,
      loading: this.store.loading$,
      loaded: this.store.loaded$,
    })
      .pipe(take(1))
      .subscribe(({ entities, loading, loaded }) => {
        if (!loading && !loaded && !entities[KE_CURRENT_USER]) {
          this.loadProfile();
        }
      });
  }

  getProfile(): Observable<Profile> {
    return this.store.entityMap$.pipe(
      map((entities) => entities[KE_CURRENT_USER]),
      filter(Boolean),
    );
  }

  getCurrentUserId(): Observable<string> {
    return this.getProfile().pipe(map((profile) => profile.id));
  }
}
