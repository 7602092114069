import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { FormControl, FormControlState, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import {
  CubeWorkflowData,
  GraphNode,
  ReadDataFrameParameters,
  ReadDataFrameRawParameters,
} from '@selfai-platform/pipeline-common';
import { DialogService } from '@selfai-platform/shell';
import { WorkflowStateService } from '@selfai-platform/storage';
import { MessageModule } from 'primeng/message';
import { WorkflowEditorFacadeService } from '../../../../workflow-editor';
import { CubeDialogManagementService } from '../../../services/cube-dialog-management.service';
import { DataSourceSelectorComponent } from '../../data-source-selector';
import { DialogHeaderService } from '../../dialog-header/dialog-header.service';

@Component({
  selector: 'selfai-platform-read-data-frame',
  templateUrl: './read-data-frame.component.html',
  styleUrl: './read-data-frame.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [CommonModule, MessageModule, DataSourceSelectorComponent, TranslateModule, ReactiveFormsModule],
  providers: [DialogHeaderService],
})
export class ReadDataFrameComponent implements OnInit {
  get node(): GraphNode<ReadDataFrameRawParameters> {
    return this.dialogService.data?.selectedNode as GraphNode<ReadDataFrameRawParameters>;
  }

  get nodeId(): string {
    return this.node.id;
  }

  get hasParameters(): boolean {
    return Boolean(this.node.parameters);
  }

  get nodeParameters(): ReadDataFrameParameters {
    return this.normalizeRawReadDataFrameParameters(this.node.parameters.serialize());
  }

  form = new FormGroup<{ dataSourceId: FormControl<string | null> }>({
    dataSourceId: new FormControl(null as FormControlState<null>, [Validators.required]),
  });

  constructor(
    private readonly workflowEditorFacadeService: WorkflowEditorFacadeService,
    private readonly w: WorkflowStateService,
    private readonly dialogService: DialogService<undefined, CubeWorkflowData<ReadDataFrameRawParameters>>,
    private readonly dialogHeaderService: DialogHeaderService,
    private readonly cubeDialogManagementService: CubeDialogManagementService,
    private readonly translateService: TranslateService,
  ) {}

  ngOnInit(): void {
    this.form.patchValue(this.nodeParameters);
    this.dialogHeaderService.initCustomHeaderComponent(
      this.nodeId,
      this.translateService.instant('workflow.cubes.read-data-frame.modal-header'),
      this.onCloseDialog.bind(this),
      this.onSubmit.bind(this),
    );
    setTimeout(() => {
      this.cubeDialogManagementService.setFocus(this.nodeId);
    });
  }

  onSubmit(): void {
    if (this.form.valid) {
      this.workflowEditorFacadeService.updateNodeParamterValues({
        id: this.nodeId,
        parameters: this.normalizeFormValuesToApiModel(this.form.value as ReadDataFrameParameters),
      });
      this.dialogService.close();
    }
  }

  onCloseDialog(): void {
    this.dialogService.close();
  }

  private normalizeFormValuesToApiModel(params: ReadDataFrameParameters): ReadDataFrameRawParameters {
    return {
      'data source': params.dataSourceId,
    };
  }

  private normalizeRawReadDataFrameParameters(rawParameters: ReadDataFrameRawParameters): ReadDataFrameParameters {
    return {
      dataSourceId: rawParameters['data source'],
    };
  }
}
