import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { PipelinePermission, SchedulerPermissionAction } from '@selfai-platform/shared';
import { CronJobsModule } from 'ngx-cron-jobs';
import { ButtonModule } from 'primeng/button';
import { CardModule } from 'primeng/card';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { PaginatorModule } from 'primeng/paginator';
import { TooltipModule } from 'primeng/tooltip';
import { combineLatest, filter, map, Observable } from 'rxjs';
import { KeCurrentUserDomainService } from '../../../current-user';
import { SchedulesPermissionService } from '../../../schedules/services/schedules-permission.service';
import { WorkflowDomainService } from '../../../wokflow-list';
import { WorkflowPresets } from '../../../workflow-presets';
import { WorkflowSettingsSchedules } from '../../interface';
import { WorkflowSettingsSchedulesFormComponent } from '../workflow-settings-schedules-form/workflow-settings-schedules-form.component';

@Component({
  selector: 'selfai-platform-workflow-settings-schedules',
  imports: [
    CommonModule,
    CardModule,
    PaginatorModule,
    ReactiveFormsModule,
    ButtonModule,
    OverlayPanelModule,
    CronJobsModule,
    WorkflowSettingsSchedulesFormComponent,
    TooltipModule,
    TranslateModule,
  ],
  templateUrl: './workflow-settings-schedules.component.html',
  styleUrl: './workflow-settings-schedules.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class WorkflowSettingsSchedulesComponent implements OnChanges, OnInit {
  showCreateForm: boolean = false;
  showEditForms: { [id: string]: boolean } = {};

  @Input({ required: true }) presets: WorkflowPresets[];
  @Input({ required: true }) schedules: WorkflowSettingsSchedules[];
  @Input({ required: true }) workflowId: string;
  @Output() addSchedule = new EventEmitter();
  @Output() editSchedule = new EventEmitter();
  @Output() deleteSchedule = new EventEmitter();

  permissions$: Observable<Record<string, boolean>>;

  hasPermissionForWorkflow$: Observable<boolean>;

  constructor(
    private readonly schedulesPermissionService: SchedulesPermissionService,
    private readonly keCurrentUserDomainService: KeCurrentUserDomainService,
    private readonly workflowDomainService: WorkflowDomainService,
  ) {}

  ngOnChanges(changes: SimpleChanges) {
    if (changes['schedule']?.currentValue) {
      this.schedules.forEach((el) => {
        this.showEditForms[el.id] = false;
      });
    }
  }

  ngOnInit(): void {
    this.workflowDomainService.loadWorkflowIfEmpty(this.workflowId);
    this.keCurrentUserDomainService.loadProfileIfEmpty();

    this.hasPermissionForWorkflow$ = combineLatest({
      userId: this.keCurrentUserDomainService.getCurrentUserId(),
      workflow: this.workflowDomainService.getWorkflow(this.workflowId).pipe(filter(Boolean)),
    }).pipe(
      map(({ userId, workflow }) => {
        if (this.schedulesPermissionService.hasPermission(PipelinePermission.WorkflowSchedulingSetAny)) {
          return true;
        }

        if (this.schedulesPermissionService.hasPermission(PipelinePermission.WorkflowSchedulingSetOwn)) {
          return userId === workflow.workflowInfo.ownerId;
        }

        return false;
      }),
    );

    this.permissions$ = combineLatest({
      canEdit: this.schedulesPermissionService.checkPermission(SchedulerPermissionAction.Update),
      canCreate: this.schedulesPermissionService.checkPermission(SchedulerPermissionAction.Create),
      canDelete: this.schedulesPermissionService.checkPermission(SchedulerPermissionAction.Delete),
    });
  }

  getPresetName(id: string): string {
    return (
      this.presets.find((el) => {
        return el.id === id;
      })?.name || ''
    );
  }

  addScheduleChange(): void {
    this.showCreateForm = !this.showCreateForm;
  }

  editScheduleChange(id: string): void {
    this.showEditForms[id] = !this.showEditForms[id];
  }

  onAddSchedule(data: WorkflowSettingsSchedules): void {
    this.addSchedule.emit(data);
    this.addScheduleChange();
  }

  onDeleteSchedule(id: string): void {
    this.deleteSchedule.emit(id);
  }

  onEditSchedule(data: WorkflowSettingsSchedules): void {
    this.editSchedule.emit(data);
    this.editScheduleChange(data.id);
  }
}
