export enum FieldType {
  JSONPATH = 'jsonpath',
  CONSTANT = 'constant',
  SPEL = 'spel',
}

export interface JsonPathApiModel {
  [FieldType.JSONPATH]: string;
}

export interface ConstantApiModel {
  [FieldType.CONSTANT]: string;
}

export interface SpelApiModel {
  [FieldType.SPEL]: string;
}

export type TypeValuesApiModel = JsonPathApiModel | ConstantApiModel | SpelApiModel;
