<div *ngIf="signature">
    <div class="text-xl mb-2">{{ 'models-registry.model-schema.field.title.inputs' | translate }}</div>

    <p-table [value]="parseInputs(signature)" [tableStyle]="{ 'min-width': '50rem' }">
      <ng-template pTemplate="header">
        <tr>
          <th>{{ 'models-registry.model-schema.field.name' | translate }}</th>
          <th>{{ 'models-registry.model-schema.field.type' | translate }}</th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-input>
        <tr>
          <td>{{ input.name }}</td>
          <td>{{ formatColumnSchema(input) }}</td>
        </tr>
      </ng-template>
    </p-table>

    <div class="mt-3 mb-3"></div>

    <div class="text-xl mb-2">{{ 'models-registry.model-schema.field.title.outputs' | translate }}</div>

    <p-table [value]="parseOutputs(signature)" [tableStyle]="{ 'min-width': '50rem' }">
      <ng-template pTemplate="header">
        <tr>
          <th>{{ 'models-registry.model-schema.field.name' | translate }}</th>
          <th>{{ 'models-registry.model-schema.field.type' | translate }}</th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-input>
        <tr>
          <td>{{ input.name }}</td>
          <td>{{ formatColumnSchema(input) }}</td>
        </tr>
      </ng-template>
    </p-table>
</div>
