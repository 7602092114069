import { ModuleWithProviders, NgModule, Provider } from '@angular/core';
import { DatasourceType } from '@selfai-platform/pipeline-common';
import { MonacoEditorModule } from 'ngx-monaco-editor-v2';
import { KE_AVALIABLE_DATASOURCES } from './common';
import { WorkflowModule } from './wokflow-list/workflow.module';
import { PipelineApiModule } from '@selfai-platform/pipeline-api';

export interface PipelineProviderModuleConfig {
  avaliableDatasources: DatasourceType[];
}

@NgModule({
  imports: [WorkflowModule.forRoot(), MonacoEditorModule.forRoot(), PipelineApiModule.forRoot()],
})
export class PipelineProviderModule {
  static forRoot(config?: PipelineProviderModuleConfig): ModuleWithProviders<PipelineProviderModule> {
    const providers: Provider[] = [];

    if (config && config.avaliableDatasources) {
      providers.push({
        provide: KE_AVALIABLE_DATASOURCES,
        useValue: config.avaliableDatasources,
      });
    }

    return {
      ngModule: PipelineProviderModule,
      providers: providers,
    };
  }
}
