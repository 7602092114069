import { Injectable } from '@angular/core';
import { ActionsForItemService, convertMessageToHtml, DataListItemAction } from '@selfai-platform/shell';
import { ModelsRegistryListItem } from '@selfai-platform/pipeline-api';
import { DynamicDialogRef } from 'primeng/dynamicdialog';
import { ConfirmationService } from 'primeng/api';
import { TranslateService } from '@ngx-translate/core';
import { combineLatest, map, Observable } from 'rxjs';
import { ModelsRegistryPermissionAction } from '@selfai-platform/shared';
import { ModelsRegistryListDomainService } from './models-registry-list-domain.service';
import { ModelsRegistryListDataService, ModelsRegistryListStore } from '../store';
import { ModelsRegistryPermissionService } from './models-registry-permission.service';

@Injectable({
  providedIn: 'root',
})
export class ModelsRegistryActionsForItemsService extends ActionsForItemService<ModelsRegistryListItem> {
  public ref: DynamicDialogRef | undefined;
  constructor(
    private readonly modelsListDomainService: ModelsRegistryListDomainService,
    private readonly modelsRegistryDataService: ModelsRegistryListDataService,
    private readonly modelsRegistryListStore: ModelsRegistryListStore,
    private readonly confirmationService: ConfirmationService,
    private readonly translateService: TranslateService,
    private readonly permissionService: ModelsRegistryPermissionService,
  ) {
    super();
  }

  override getActionsForItem(model: ModelsRegistryListItem): Observable<DataListItemAction[]> {
    return combineLatest({
      deleteTooltipTranslate: this.translateService.get('models-registry-list.actions.delete'),
      deletePermission: this.permissionService.checkPermission(ModelsRegistryPermissionAction.Delete),
    }).pipe(
      map(({ deleteTooltipTranslate, deletePermission }) => {
        if (deletePermission) {
          return [
            {
              labelTranslate: deleteTooltipTranslate,
              icon: 'pi pi-trash',
              action: () => this.delete(model),
            },
          ] as DataListItemAction[];
        } else {
          return [];
        }
      }),
    );
  }

  private delete(modelRegistryListItem: ModelsRegistryListItem): void {
    this.confirmationService.confirm({
      key: 'dialog',
      message: convertMessageToHtml(this.translateService.instant('models-registry-list.actions.delete.confirmation')),
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        this.modelsListDomainService.deleteModel(modelRegistryListItem.id);
      },
    });
  }
}
