<p-panel [header]="'schedules.edit.header' | translate">
  <div *ngIf="isLoading" class="w-full mb-6 mt-5">
    <div class="flex w-full mb-2">
      <p-skeleton height="2.5rem" width="27.5rem" styleClass="mr-3"></p-skeleton>
    </div>
    <div *ngIf="editMode" class="flex w-full mb-2">
      <p-skeleton height="2.5rem" width="27.5rem" styleClass="mr-3"></p-skeleton>
      <p-skeleton height="2.5rem" width="27.5rem" styleClass="mr-3"></p-skeleton>
      <p-skeleton height="2.5rem" width="36rem" styleClass="mr-2"></p-skeleton>
    </div>
  </div>

  <div class="schedule-edit-dialog" *ngIf="!isLoading">
    <div class="grid mt-3 mb-3">
      <div class="col-3 mb-2">
        <p-floatLabel>
          <p-dropdown
            class="w-full"
            id="workflows"
            [options]="workflowsByPermissions$ | async"
            [(ngModel)]="selectedWorkflow"
            [disabled]="editMode || !canUpdate"
            optionLabel="name"
          ></p-dropdown>
          <label class="block mb-2" for="workflows">{{ 'schedules.form.workflow' | translate }}</label>
        </p-floatLabel>
      </div>
    </div>

    <selfai-platform-workflow-settings-schedules-form
      *ngIf="editMode"
      [disabled]="!canUpdate"
      [showBtn]="false"
      [workflowId]="selectedWorkflow.id"
      [schedule]="currentSchedule"
      [presets]="presets"
      (currentFormChanged)="setScheduleData($event)"
    ></selfai-platform-workflow-settings-schedules-form>

    <selfai-platform-workflow-settings-schedules-form
      *ngIf="!editMode && selectedWorkflow"
      [disabled]="!canUpdate"
      [showBtn]="false"
      [workflowId]="selectedWorkflow.id"
      [presets]="presets"
      (currentFormChanged)="setScheduleData($event)"
    ></selfai-platform-workflow-settings-schedules-form>
  </div>
  <div class="flex">
    <p-button
      class="mr-2"
      label="{{ 'schedules.edit.actions.back' | translate }}"
      severity="secondary"
      [outlined]="true"
      (click)="onBackEvent()"
    ></p-button>

    <p-button
      label="{{ 'schedules.edit.actions.save' | translate }}"
      severity="primary"
      [disabled]="!canUpdate || !selectedWorkflow"
      (click)="onSubmitEvent()"
    ></p-button>
  </div>
</p-panel>
