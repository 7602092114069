<div class="model-tad-add-dialog">
  <div class="mt-4 mb-4 w-full">
    <p-floatLabel>
      <input id="tagName" class="w-full" type="text" pInputText [(ngModel)]="newTagKey" />
      <label for="tagName">{{ 'models-registry.model-tag-add-dialog.field.tag-name' | translate }}</label>
    </p-floatLabel>
  </div>

  <div class="mt-4 mb-4 w-full">
    <p-floatLabel>
      <input id="tagValue" class="w-full" type="text" pInputText [(ngModel)]="newTagValue" />
      <label for="tagName">{{ 'models-registry.model-tag-add-dialog.field.tag-value' | translate }}</label>
    </p-floatLabel>
  </div>

  <div class="flex">
    <p-button
      class="mr-2"
      label="{{ 'models-registry.model-tag-add-dialog.button.close.label' | translate }}"
      [outlined]="true"
      severity="secondary"
      (click)="cancel()"
    ></p-button>
    <p-button
      label="{{ 'models-registry.model-tag-add-dialog.button.add.label' | translate }}"
      severity="success"
      (click)="addTagToModel()"
    ></p-button>
  </div>
</div>
