import { Injectable } from '@angular/core';
import { combineLatest, map, Observable, take } from 'rxjs';
import { WorkflowState, WorkflowStore } from '../../stores';

@Injectable({ providedIn: 'root' })
export class WorkflowDomainService {
  constructor(private readonly store: WorkflowStore) {}

  loadWorkflow(workflowId: string): Observable<WorkflowState> {
    return this.store.getByKey(workflowId);
  }

  loadWorkflowIfEmpty(workflowId: string): void {
    combineLatest({
      workflow: this.getWorkflow(workflowId),
      loading: this.getWorkflowLoading(),
    })
      .pipe(take(1))
      .subscribe(({ workflow, loading }) => {
        if (!loading && !workflow) {
          this.loadWorkflow(workflowId).pipe(take(1)).subscribe();
        }
      });
  }

  getWorkflow(workflowId: string): Observable<WorkflowState> {
    return this.store.entityMap$.pipe(map((entities) => entities?.[workflowId]));
  }

  getWorkflowLoading(): Observable<boolean> {
    return this.store.loading$;
  }

  getWorkflowError(): Observable<unknown> {
    return this.store.errors$;
  }

  getWorkflowSuccess(): Observable<boolean> {
    return this.store.loaded$;
  }
}
