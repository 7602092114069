import { Injectable } from '@angular/core';
import {
  PermissionService,
  PipelinePermission,
  PipelinePermissionEntity,
  WorkflowPermissionAction,
} from '@selfai-platform/shared';
import { Observable, combineLatestWith, filter, forkJoin, map, of, switchMap, take } from 'rxjs';
import { KeCurrentUserDomainService } from '../../../current-user';
import { KeUserService } from '../../../user';
import { WorkflowDetailService } from '../workflow/workflow-detail.service';

@Injectable({
  providedIn: 'root',
})
export class WorkflowPermissionService {
  private currentUser$ = this.keCurrentUserDomainService.getProfile();

  get workflowPermissionsCore(): WorkflowPermissionAction[] {
    return Object.values(WorkflowPermissionAction);
  }

  constructor(
    private readonly workflowDetailService: WorkflowDetailService,
    private readonly permissionService: PermissionService,
    private readonly keUserProfileService: KeUserService,
    private readonly keCurrentUserDomainService: KeCurrentUserDomainService,
  ) {}

  getLegacyWorkflowPermissions(workflowId: string): Observable<WorkflowPermissionAction[]> {
    return this.permissionService.pipelinePermissionsLoadedObs$.pipe(
      filter((loaded) => loaded),
      combineLatestWith(
        this.workflowDetailService.loadWorkflow(workflowId),
        this.keUserProfileService.getCurrentUserProfile(),
      ),
      switchMap(([loaded, workflow, userProfile]) => {
        const isOwner = workflow.workflowInfo.ownerId === userProfile.id;
        const permissionChecks$ = this.workflowPermissionsCore.map((permissionAction) =>
          this.permissionService
            .checkPermission({
              entity: PipelinePermissionEntity.Workflows,
              action: permissionAction,
              isOwner,
              isPublic: workflow.thirdPartyData.gui.isPublic,
            })
            .pipe(
              map((hasPermission) => (hasPermission ? permissionAction : null)),
              take(1),
            ),
        );

        return forkJoin(permissionChecks$).pipe(map((results) => results.filter((result) => result !== null)));
      }),
    );
  }

  hasCurrentPermission(permission: PipelinePermission): boolean {
    return this.getWorkflowsPermissions().includes(permission);
  }

  canViewWorkflow(ownerId: string, isPublic: boolean): Observable<boolean> {
    if (
      this.hasCurrentPermission(PipelinePermission.WorkflowGetAny) ||
      (isPublic && this.hasCurrentPermission(PipelinePermission.WorkflowGetOwn))
    ) {
      return of(true);
    }

    return this.equalCurrentUser(ownerId).pipe(
      map((equalCurrentUser) => equalCurrentUser && this.hasCurrentPermission(PipelinePermission.WorkflowGetOwn)),
    );
  }

  canDeleteWorkflow(ownerId: string, isPublic: boolean): Observable<boolean> {
    if (
      this.hasCurrentPermission(PipelinePermission.WorkflowDeleteAny) ||
      (isPublic && this.hasCurrentPermission(PipelinePermission.WorkflowDeleteOwn))
    ) {
      return of(true);
    }

    return this.equalCurrentUser(ownerId).pipe(
      map((equalCurrentUser) => equalCurrentUser && this.hasCurrentPermission(PipelinePermission.WorkflowDeleteOwn)),
    );
  }

  canCloneWorkflow(ownerId: string, isPublic: boolean): Observable<boolean> {
    if (
      this.hasCurrentPermission(PipelinePermission.WorkflowCloneAny) ||
      (isPublic && this.hasCurrentPermission(PipelinePermission.WorkflowCloneOwn))
    ) {
      return of(true);
    }

    return this.equalCurrentUser(ownerId).pipe(
      map((equalCurrentUser) => equalCurrentUser && this.hasCurrentPermission(PipelinePermission.WorkflowCloneOwn)),
    );
  }

  canRunWorkflow(ownerId: string, isPublic: boolean): Observable<boolean> {
    if (
      this.hasCurrentPermission(PipelinePermission.WorkflowRunAny) ||
      (isPublic && this.hasCurrentPermission(PipelinePermission.WorkflowRunOwn))
    ) {
      return of(true);
    }

    return this.equalCurrentUser(ownerId).pipe(
      map((equalCurrentUser) => equalCurrentUser && this.hasCurrentPermission(PipelinePermission.WorkflowRunOwn)),
    );
  }

  canExecuteWorkflow(ownerId: string, isPublic: boolean): Observable<boolean> {
    if (
      this.hasCurrentPermission(PipelinePermission.WorkflowExecuteAny) ||
      (isPublic && this.hasCurrentPermission(PipelinePermission.WorkflowExecuteOwn))
    ) {
      return of(true);
    }

    return this.equalCurrentUser(ownerId).pipe(
      map((equalCurrentUser) => equalCurrentUser && this.hasCurrentPermission(PipelinePermission.WorkflowExecuteOwn)),
    );
  }

  canStopWorkflow(ownerId: string, isPublic: boolean): Observable<boolean> {
    if (
      this.hasCurrentPermission(PipelinePermission.WorkflowStopAny) ||
      (isPublic && this.hasCurrentPermission(PipelinePermission.WorkflowStopOwn))
    ) {
      return of(true);
    }

    return this.equalCurrentUser(ownerId).pipe(
      map((equalCurrentUser) => equalCurrentUser && this.hasCurrentPermission(PipelinePermission.WorkflowStopOwn)),
    );
  }

  canUpdateWorkflow(ownerId: string, isPublic: boolean): Observable<boolean> {
    if (
      this.hasCurrentPermission(PipelinePermission.WorkflowUpdateAny) ||
      (isPublic && this.hasCurrentPermission(PipelinePermission.WorkflowUpdateOwn))
    ) {
      return of(true);
    }

    return this.equalCurrentUser(ownerId).pipe(
      map((equalCurrentUser) => equalCurrentUser && this.hasCurrentPermission(PipelinePermission.WorkflowUpdateOwn)),
    );
  }

  private equalCurrentUser(userId: string): Observable<boolean> {
    return this.currentUser$.pipe(map((data) => data.id === userId));
  }

  private getWorkflowsPermissions(): PipelinePermission[] {
    return this.permissionService.getPipelinePermissions().filter((t) => t.startsWith('workflows'));
  }
}
