import { ChangeDetectionStrategy, Component } from '@angular/core';
import { AsyncPipe, JsonPipe, NgForOf } from '@angular/common';
import {
  DataListViewComponent,
  DataListViewTemplateNameDirective,
  KE_MODELS_REGISTRY_PATH,
  KE_ROOT_ROUTE,
  provideDataListView,
  provideDialogService,
  ToolbarAction,
} from '@selfai-platform/shell';
import { TranslateModule } from '@ngx-translate/core';
import { combineLatest, map, Observable } from 'rxjs';
import { Router } from '@angular/router';
import { DestroyService, ModelsRegistryPermissionAction } from '@selfai-platform/shared';
import {
  ModelsRegistryActionsForItemsService,
  ModelsRegistryDataListViewService,
  ModelsRegistryPermissionService,
} from '../../services';
import { FILTER_FIELDS } from '../../constants';
import { TagModule } from 'primeng/tag';

@Component({
  selector: 'selfai-platform-models-registry-list',
  templateUrl: './models-registry-list.component.html',
  styleUrls: ['./models-registry-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [AsyncPipe, DataListViewComponent, TranslateModule, DataListViewTemplateNameDirective, NgForOf, TagModule],
  providers: [
    DestroyService,
    ...provideDialogService(),
    ...provideDataListView(ModelsRegistryDataListViewService, ModelsRegistryActionsForItemsService),
  ],
})
export class ModelsRegistryListComponent {
  public columns = this.modelsRegistryDataListViewService.getColumns();
  public actions$: Observable<ToolbarAction[]> = this.getActions();
  public groupActions$ = this.modelsRegistryDataListViewService.getGroupActions();
  public filtersFields = FILTER_FIELDS;

  constructor(
    private readonly modelsRegistryDataListViewService: ModelsRegistryDataListViewService,
    private readonly permissionService: ModelsRegistryPermissionService,
    private readonly router: Router,
  ) {}

  private getActions(): Observable<ToolbarAction[]> {
    return combineLatest({
      createPermission: this.permissionService.checkPermission(ModelsRegistryPermissionAction.Create),
    }).pipe(
      map(({ createPermission }) => {
        const actions = [];
        if (createPermission) {
          actions.push({
            tooltipTranslate: 'models-registry-list.toolbar.actions.create',
            icon: 'pi pi-plus-circle',
            buttonClass: 'p-button-success',
            action: () => {
              this.router.navigate(['/', KE_ROOT_ROUTE, KE_MODELS_REGISTRY_PATH, 'create']).then();
            },
          });
        }
        return actions as ToolbarAction[];
      }),
    );
  }
}
