import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, map, of } from 'rxjs';
import { AggregatorProcess, AggregatorProcessApi, AggregatorProcessListApi, AggregatorRouteApiModel } from '../models';
import { AggregatorAdapter } from './aggregator.adapter';
import { AggrtConfigService, AggregatorCoreApiService } from '@selfai-platform/pipeline-shared';

@Injectable({
  providedIn: 'root',
})
export class AggregatorRoutesApiService extends AggregatorCoreApiService implements AggregatorAdapter {
  constructor(
    private readonly http: HttpClient,
    aggrtConfigService: AggrtConfigService,
  ) {
    super(aggrtConfigService);
  }

  loadProcessWithRoutes(): Observable<AggregatorProcessApi> {
    const url = this.buildUrl('/routes/getCurrent');

    return this.http.get<AggregatorRouteApiModel[]>(url).pipe(
      map((routes) => ({
        routes,
        // for supporting with rnkb
        process: {} as AggregatorProcess,
      })),
    );
  }

  saveRoutes({ routes }: AggregatorProcessApi): Observable<void> {
    const url = this.buildUrl('/routes/update');

    return this.http.post<void>(url, routes);
  }

  loadProcessList(): Observable<AggregatorProcessListApi> {
    return of([]);
  }

  loadProcessListFull(): Observable<AggregatorProcessApi[]> {
    return of([]);
  }
}
