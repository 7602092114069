import { ModuleWithProviders, NgModule } from '@angular/core';
import {
  DataSourcesApiService,
  DataSourceLibraryApiService,
  ModelsRegistryApiService,
  OperationsApiService,
  SessionManagerApiService,
  WorkflowApiService,
  WorkflowPresetsApiService,
} from './';
@NgModule()
export class PipelineApiModule {
  static forRoot(): ModuleWithProviders<PipelineApiModule> {
    return {
      ngModule: PipelineApiModule,
      providers: [
        SessionManagerApiService,
        DataSourcesApiService,
        DataSourceLibraryApiService,
        ModelsRegistryApiService,
        WorkflowPresetsApiService,
        WorkflowApiService,
        OperationsApiService,
      ],
    };
  }
}
