import { Injectable } from '@angular/core';
import { DefaultDataService, HttpUrlGenerator } from '@ngrx/data';
import { IModel, ModelsRegistryListItem } from '@selfai-platform/pipeline-api';
import { HttpClient } from '@angular/common/http';
import { KE_MODELS_REGISTRY_ENTITY_NAME } from '@selfai-platform/store';
import { map, Observable } from 'rxjs';
import { Update } from '@ngrx/entity/src/models';
import { ModelsRegistryApiService } from '@selfai-platform/pipeline-api';

@Injectable({
  providedIn: 'root',
})
export class ModelsRegistryListDataService extends DefaultDataService<IModel> {
  constructor(
    public override http: HttpClient,
    public override httpUrlGenerator: HttpUrlGenerator,
    private readonly modelsRegistryApiService: ModelsRegistryApiService,
  ) {
    super(KE_MODELS_REGISTRY_ENTITY_NAME, http, httpUrlGenerator);
  }

  override getAll(): Observable<ModelsRegistryListItem[]> {
    return this.modelsRegistryApiService
      .loadList()
      .pipe(map((items) => items.map((item) => ({ ...item, id: item.name }))));
  }

  override getById(id: string): Observable<ModelsRegistryListItem> {
    return this.modelsRegistryApiService.getModel(id).pipe(map((item) => ({ ...item, id: item.name })));
  }

  override add(data: ModelsRegistryListItem): Observable<ModelsRegistryListItem> {
    return this.modelsRegistryApiService.addModel(data).pipe(map((item) => ({ ...item })));
  }

  override update(data: Update<ModelsRegistryListItem>): Observable<ModelsRegistryListItem> {
    return this.modelsRegistryApiService.updateModel(data).pipe(map((item) => ({ ...item })));
  }

  override delete(id: string): Observable<string> {
    return this.modelsRegistryApiService.deleteModel(id);
  }
}
