import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  Output,
  SimpleChanges,
} from '@angular/core';
import { AutoCompleteModule } from 'primeng/autocomplete';
import { Button } from 'primeng/button';
import { InputTextModule } from 'primeng/inputtext';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { AsyncPipe, DatePipe, JsonPipe, NgIf } from '@angular/common';
import { PaginatorModule } from 'primeng/paginator';
import { PanelModule } from 'primeng/panel';
import { FormBuilder, FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import {
  IModelsRegistryModelTag,
  IModelsRegistryRun,
  ModelsRegistryVersionsListItem,
} from '@selfai-platform/pipeline-api';
import {
  BreadcrumbsMenuItem,
  BreadcrumbsMenuService,
  KE_MODELS_REGISTRY_PATH,
  KE_ROOT_ROUTE,
  provideDialogService,
} from '@selfai-platform/shell';
import { ActivatedRoute, Router } from '@angular/router';
import { ModelsRegistryApiService } from '@selfai-platform/pipeline-api';
import { ModelsRegistryPermissionService } from '../../services';
import { ModelsRegistryVersionsListStore } from '../../store';
import { combineLatest, map, Observable, switchMap, take, takeUntil } from 'rxjs';
import { DestroyService, ModelsRegistryPermissionAction } from '@selfai-platform/shared';
import { ModelSchemaComponent } from '../model-schema';
import { CardModule } from 'primeng/card';
import { ChipsModule } from 'primeng/chips';
import { ModelTagAddDialogComponent } from '../model-tag-add-dialog';
import { DialogService, DynamicDialogRef } from 'primeng/dynamicdialog';
import { data } from 'jquery';

@Component({
  selector: 'selfai-platform-model-version',
  templateUrl: './model-version.component.html',
  styleUrls: ['./model-version.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    AutoCompleteModule,
    Button,
    InputTextModule,
    InputTextareaModule,
    NgIf,
    PaginatorModule,
    PanelModule,
    ReactiveFormsModule,
    TranslateModule,
    ModelSchemaComponent,
    AsyncPipe,
    CardModule,
    DatePipe,
    ChipsModule,
  ],
  providers: [...provideDialogService()],
})
export class ModelVersionComponent {
  public ref: DynamicDialogRef;
  public id: string = this.activatedRoute?.snapshot?.params['versionId'];
  public modelId: string = this.activatedRoute?.snapshot?.params['modelId'];
  public editMode = !!this.id;
  public modelVersionForm: FormGroup;

  public runs$: Observable<IModelsRegistryRun>;
  public signature$: Observable<Record<string, string>>;

  public breadcrumbItems: BreadcrumbsMenuItem[] = [
    {
      label: this.translate.instant('shell.menu.models-registry'),
      routerLink: ['/', KE_ROOT_ROUTE, KE_MODELS_REGISTRY_PATH],
    },
  ];
  public canUpdate: boolean = true;

  @Input() versions: ModelsRegistryVersionsListItem[];
  @Input() currentVersion: ModelsRegistryVersionsListItem;

  @Output() backEvent: EventEmitter<void> = new EventEmitter<void>();
  @Output() submitEvent: EventEmitter<ModelsRegistryVersionsListItem> =
    new EventEmitter<ModelsRegistryVersionsListItem>();

  constructor(
    private readonly fb: FormBuilder,
    private readonly route: ActivatedRoute,
    private readonly changeDetectorRef: ChangeDetectorRef,
    private readonly activatedRoute: ActivatedRoute,
    private readonly translate: TranslateService,
    private readonly breadcrumbsMenuService: BreadcrumbsMenuService,
    private readonly modelsRegistryPermissionService: ModelsRegistryPermissionService,
    private readonly router: Router,
    private readonly modelsRegistryVersionsListStore: ModelsRegistryVersionsListStore,
    private readonly modelsRegistryApiService: ModelsRegistryApiService,
    private readonly dialogService: DialogService,
    private readonly destroy$: DestroyService,
  ) {}

  public createForm(): void {
    this.modelVersionForm = this.fb.group({
      version: new FormControl({ value: '', disabled: this.editMode }, [Validators.required]),
      description: new FormControl(''),
      tags: new FormControl(''),
    });
  }

  public onBackEvent(): void {
    this.backEvent.emit();
  }

  public onSubmitEvent(): void {
    const preparedData = {
      ...this.modelVersionForm.value,
    };
    if (this.editMode) {
      preparedData.id = this.id;
    }
    this.submitEvent.emit(preparedData);
  }

  public ngOnInit(): void {
    if (!this.editMode) {
      this.breadcrumbItems?.push({
        label: `${this.translate.instant('shell.bc.models-registry.label.create')}`,
        routerLink: ['/', KE_ROOT_ROUTE, KE_MODELS_REGISTRY_PATH, 'create'],
      });
      this.breadcrumbsMenuService.setBreadcrumbsMenu(this.breadcrumbItems);
      this.createForm();
      this.changeDetectorRef.detectChanges();
    }
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes['versions']?.currentValue?.length && this.editMode && !this.currentVersion) {
      this.currentVersion = this.versions.find((model) => {
        return model.version === this.id;
      });

      this.runs$ = this.modelsRegistryApiService.getRuns(this.currentVersion.run_id);
      this.signature$ = this.modelsRegistryApiService
        .getArtifacts(this.currentVersion.name, this.currentVersion.version)
        .pipe(
          takeUntil(this.destroy$),
          map((data) => data.signature),
        );

      this.breadcrumbItems?.push({
        label: `${this.translate.instant('shell.bc.models-registry.label.edit')} ${this.currentVersion?.name}`,
        routerLink: ['/', KE_ROOT_ROUTE, KE_MODELS_REGISTRY_PATH, 'edit', this.currentVersion?.id],
      });
      this.breadcrumbsMenuService.setBreadcrumbsMenu(this.breadcrumbItems);
      combineLatest([
        this.modelsRegistryPermissionService.checkPermission(ModelsRegistryPermissionAction.Update),
        this.modelsRegistryPermissionService.checkPermission(ModelsRegistryPermissionAction.Get),
      ]).subscribe(([canUpdate, canView]) => {
        if (!canView) {
          this.router.navigate(['access-denied']).then();
        }
        this.canUpdate = canUpdate;
        if (!canUpdate) {
          this.modelVersionForm.disable();
        }
      });
      this.createForm();
      this.modelVersionForm.get('name')?.disable();
      this.modelVersionForm.patchValue(this.currentVersion);
      this.changeDetectorRef.detectChanges();
    }

    if (!this.currentVersion) {
      this.modelsRegistryApiService
        .getModelVersion(this.modelId, this.id)
        .pipe(takeUntil(this.destroy$))
        .subscribe((version) => {
          this.currentVersion = version as ModelsRegistryVersionsListItem;
          this.runs$ = this.modelsRegistryApiService.getRuns(this.currentVersion.run_id);
          this.signature$ = this.modelsRegistryApiService
            .getArtifacts(this.currentVersion.name, this.currentVersion.version)
            .pipe(
              takeUntil(this.destroy$),
              map((data) => data.signature),
            );
          this.createForm();
          this.modelVersionForm.patchValue(this.currentVersion);
          this.changeDetectorRef.detectChanges();
        });
    }
  }

  public deleteTag({ originalEvent, value }: { originalEvent: Event; value: IModelsRegistryModelTag }): void {
    this.modelsRegistryApiService
      .deleteTag(this.currentVersion.name, value, this.currentVersion.version)
      .pipe(takeUntil(this.destroy$))
      .subscribe();
  }

  public openModalTagAddDialog(): void {
    this.ref = this.dialogService.open(ModelTagAddDialogComponent, {
      width: '40vw',
      modal: true,
      breakpoints: {
        '960px': '75vw',
        '640px': '90vw',
      },
      data: this.currentVersion,
    });

    this.ref.onClose
      .pipe(
        switchMap((data) =>
          this.modelsRegistryApiService.addTag(this.currentVersion, data, this.currentVersion.version),
        ),
        takeUntil(this.destroy$),
      )
      .subscribe();
  }
}
