import { StepApiModel } from '@selfai-platform/aggregator-api';
import { FormControls, FormFieldsValueNode, TypeFieldValues } from '../models';
import { TypeValuesApiModel } from '@selfai-platform/pipeline-shared';

export function mapFormToNode(formFieldValues: FormFieldsValueNode): StepApiModel {
  return Object.fromEntries(
    Object.entries(formFieldValues).map(([key, field]) => {
      if (Array.isArray(field)) {
        if (key === 'steps') {
          return [key, field.map(mapFormToNode)];
        }

        return [key, (field as TypeFieldValues[]).map(mapFormFieldTyped)];
      }

      if (isFormFieldTyped(field)) {
        return [key, mapFormFieldTyped(field)];
      }

      return [key, field];
    }),
  );
}

function mapFormFieldTyped(field: TypeFieldValues): Partial<TypeValuesApiModel> {
  return { [field.type]: field.value };
}

function isFormFieldTyped(formFieldValue: FormControls): formFieldValue is TypeFieldValues {
  return formFieldValue instanceof Object && Object.prototype.hasOwnProperty.call(formFieldValue, 'value');
}
